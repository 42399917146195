export const fillingInstructionsEn = [
	{
		name: 'Education',
		text:
			'This section includes the following information:' +
			'<ul>' +
			'<li>name of the educational institution,</li>' +
			'<li>faculty,</li>' +
			'<li>speciality.</li>' +
			'</ul>' +
			'The name of the educational institution should not contain the name of the city/country by which it can be identified. It is better to simply put “State University”.',
	},
];
export const fillingInstructionsRu = [
	{
		name: 'Образование',
		text:
			'В этот раздел входит следующая информация:' +
			'<ul>' +
			'<li>наименование учебного заведения,</li>' +
			'<li>факультет,</li>' +
			'<li>специальность.</li>' +
			'</ul>' +
			'В названии учебного заведения не следует указывать название города/страны — те данные, по которым его можно идентифицировать. Необходимо оставить просто Государственный Университет.',
	},
];
