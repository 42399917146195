import React, { useState } from 'react';
import styles from './style.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { DragIndicatorSvg } from '@app/assets/svg';
import EditItem from '@shared/ui/edit-item';
import { Collapse, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';
import { ModalCloseEvent, ModalOpenEvent } from '@processes/modal';
import FormComponent from '../form';
import { CvModel } from '@entities/cv';
import classNames from 'classnames';
import ChildrenList from '../children-list';
import { onDragChildren, findSkill, updateSkills } from '../lib';
import { useLocale } from '@processes/app/lib';
import { EditCvModel } from '@features/edit-cv';

const { Text, Title } = Typography;
const { Panel } = Collapse;

const translation = {
	title: {
		ru: 'Редактирование навыка',
		en: 'Skill editing',
	},
	list_skills: {
		ru: 'Список добавленных навыков',
		en: 'List of added skills',
	},
	empty_list: {
		ru: 'Нет добавленных навыков',
		en: 'No skills added',
	},
	category: {
		ru: 'Категория',
		en: 'Category',
	},
	skill: {
		ru: 'Навык',
		en: 'Skill',
	},
	level: {
		ru: 'Уровень',
		en: 'Level',
	},
};

const List = () => {
	const [isDragging, setIsDragging] = useState<boolean | null>(null);
	const { locale } = useLocale();

	const { setValue, watch } = useFormContext<EditCvModel.CvFormDto>();

	const skillsList = watch('skills');

	const onDragHandler = (newState: EditCvModel.CvFormDto['skills']) => {
		setValue('skills', newState, { shouldDirty: typeof isDragging === 'boolean' });
	};
	const onDragHandlerChildren =
		(category: string) => (newState: EditCvModel.TSkillsForm, shouldDirty: boolean) => {
			setValue('skills', onDragChildren(skillsList, newState, category), {
				shouldDirty,
			});
		};
	const onDelete = (indexItem: string) => () => {
		const filtered = skillsList.filter(({ id }) => id !== indexItem);
		setValue('skills', filtered, { shouldDirty: true });
	};
	const onEditSkill = (categoryId: string, index: string) => (value: CvModel.TSkills) => {
		setValue(
			'skills',
			updateSkills(skillsList, { category: categoryId, id: index }, { index: index, ...value }),
			{ shouldDirty: true },
		);
		ModalCloseEvent.Close();
	};

	const showModal = (categoryId: string) => (idSkillChildren: string) => {
		const skill = findSkill(skillsList, categoryId, idSkillChildren);
		if (skill) {
			ModalOpenEvent.Open({
				title: translation.title[locale],
				component: (
					<FormComponent
						modal
						closeModal={ModalCloseEvent.Close}
						onSetValue={onEditSkill(categoryId, skill.index)}
						defaultValue={skill}
					/>
				),
			});
		}
	};

	const genExtra = (id: string) => (
		<div
			className={styles.action}
			onClick={(event: any) => {
				event.stopPropagation();
			}}
		>
			<EditItem onDelete={onDelete(id)} />
		</div>
	);

	return (
		<div className={styles.list}>
			<div className={styles.title}>
				<Title>{translation.list_skills[locale]}</Title>
			</div>
			<div className={styles.elements}>
				{skillsList.length === 0 && (
					<div className={styles.list_empty}>
						<Text>{translation.empty_list[locale]}</Text>
					</div>
				)}
				<ReactSortable
					onStart={() => setIsDragging(true)}
					onUnchoose={() => setIsDragging(false)}
					list={skillsList}
					setList={onDragHandler}
					animation={250}
					handle={'.handle'}
				>
					{skillsList.map(({ id, item }) => (
						<Collapse
							expandIconPosition={'end'}
							className={classNames(styles.category, { isDragging: isDragging })}
							key={id}
							items={[
								{
									className: classNames(styles.panel),
									key: id,
									label: (
										<div className={styles.panel__content}>
											<DragIndicatorSvg className={classNames('handle', styles.dragIcon)} />
											<div className={styles.header}>
												<span
													className={styles.header__label}
												>{`${translation.category[locale]}: `}</span>
												<span className={styles.header__value}>{id}</span>
											</div>
											{genExtra(id)}
										</div>
									),
									children: (
										<div className={styles.skills}>
											<div className={styles.skills__header}>
												<div className={styles.name}>{translation.skill[locale]}</div>
												<div className={styles.level}>{translation.level[locale]}</div>
											</div>
											<ChildrenList
												skills={item}
												categoryId={id}
												handler={onDragHandlerChildren(id)}
												showModal={showModal(id)}
											/>
										</div>
									),
								},
							]}
						></Collapse>
					))}
				</ReactSortable>
			</div>
		</div>
	);
};

export default List;
