import { NotificationType } from '@models/notification';
import eventsBus from '@shared/lib/eventsBus';

const eventName = 'NotificationOpen';
const subscribe = (callback: Function) => {
	return eventsBus.subscribe(eventName, callback);
};

const open = (data: NotificationType) => {
	eventsBus.broadcast(eventName, data);
};
const events = {
	subscribe,
	open,
};
export default events;
