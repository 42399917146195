import { ZodError, ZodSchema } from 'zod';
import { AppMode } from '@shared/config';
import { Logger } from '@shared/services';
import { Mode } from '@shared/types/core';

class ZodParserInstance {
	private mode: Mode = AppMode();
	private showError(data: any, error: ZodError | any, info?: string) {
		Logger.group({ collapsed: true, label: `Parse error ${info ? info : ''}` })
			.log(data)
			.log(error.errors)
			.groupEnd();
	}
	parse(schema: ZodSchema, data: any, info?: string) {
		if (this.mode === 'prod') {
			return;
		}
		try {
			schema.parse(data, {});
		} catch (e: ZodError | any) {
			this.showError(data, e, info);
			if (this.mode === 'dev') {
				alert('Ошибка обработки данных');
			}
		}
	}
	async parseAsync(schema: ZodSchema, data: any[]) {
		if (this.mode === 'prod') {
			return;
		}
		try {
			await schema.parseAsync(data);
		} catch (e: ZodError | any) {
			this.showError(data, e);
			if (this.mode === 'dev') {
				alert('Ошибка обработки данных');
			}
		}
	}
}
export const ZodParser = new ZodParserInstance();
