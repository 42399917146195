import { SelectSvg } from '@app/assets/svg';
import { Select } from 'antd';
import React from 'react';
import styles from './style.module.scss';
import { useAppStore } from '@processes/app/store';

const { Option } = Select;

const SelectLocale = () => {
	const locale = useAppStore((state) => state.locale);
	const ChangeLocal = useAppStore((state) => state.ChangeLocal);
	return (
		<Select
			className={styles.select_locale}
			popupClassName="select_language"
			placeholder="En"
			suffixIcon={<SelectSvg />}
			value={locale}
			onChange={(value) => ChangeLocal(value)}
		>
			<Option value="en">En</Option>
			<Option value="ru">Ru</Option>
		</Select>
	);
};

export default SelectLocale;
