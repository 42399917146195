import React from 'react';
import { useRoutes } from 'react-router-dom';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import { RequiredAuth } from '@processes/private-routing';
import NotFoundPage from '@pages/404';
import CvPage from '@pages/cv';
import GuestPage from '@pages/guest';
import { AuthenticationRoutes } from '@pages/authentification';
import ErrorPage from '@pages/error-page';
import EditCv from '@pages/edit-cv';
import SuspenseComponent from '@shared/ui/suspense';
import MainTemplate from '@shared/ui/main-template';

const Pages = () => {
	const routesArr = [
		...AuthenticationRoutes,
		{
			path: 'guest',
			element: (
				<ErrorBoundary description={''}>
					<GuestPage />
				</ErrorBoundary>
			),
		},
		{
			path: '',
			element: <MainTemplate />,
			children: [
				{
					path: '',
					element: (
						<ErrorBoundary description={''}>
							<RequiredAuth>
								<CvPage />
							</RequiredAuth>
						</ErrorBoundary>
					),
				},
				{
					path: 'cv/:cvId',
					element: (
						<ErrorBoundary description={''}>
							<RequiredAuth>
								<EditCv />
							</RequiredAuth>
						</ErrorBoundary>
					),
				},
			],
		},
		{
			path: '*',
			element: <NotFoundPage />,
		},
		{
			path: '/error',
			element: <ErrorPage />,
		},
	];
	const routes = useRoutes(routesArr);
	return <SuspenseComponent>{routes}</SuspenseComponent>;
};

export default Pages;
