import { useController, UseControllerProps } from 'react-hook-form';
import { Select, SelectProps } from 'antd';
import classNames from 'classnames';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';
const { Option } = Select;

type TProps = {
	main: { label?: string } & SelectProps;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
} & UseControllerProps<any>;

export function SelectField({ main, setValue, trigger, ...props }: TProps) {
	const { className: classProps, disabled, options, mode, ...rest } = main;
	const {
		field: { value, name, onChange, ...restField },
		fieldState: { invalid, error },
	} = useController(props);

	const onChangeSelect = (value: string | string[], _options: any) => {
		if (mode === 'multiple') {
			if (value.length === 0) setValue(name, null);
			else setValue(name, value as string[]);
		} else {
			if (!value) setValue(name, null);
			else setValue(name, value as string);
		}
		trigger(name);
	};
	return (
		<div className="form-item">
			{main.label && (
				<label className={classNames('label', { disabled: disabled })}>{main.label}</label>
			)}
			<Select
				className={classNames(classProps)}
				placeholder={main.placeholder}
				id={name}
				disabled={disabled}
				showAction={['focus', 'click']}
				status={invalid ? 'error' : ''}
				value={value ? value : []}
				onChange={onChangeSelect}
				mode={mode}
				{...rest}
				{...restField}
				getPopupContainer={(trigger) => trigger.parentNode}
				maxTagCount={'responsive'}
				filterOption={(input, option) => {
					if (option === undefined || null) return false;
					return option['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;
				}}
			>
				{options?.map((el) => (
					<Option key={el.value} value={el.value} data-label={el.label}>
						{el.label}
					</Option>
				))}
			</Select>
			{invalid && <div className="error-text">{error?.message}</div>}
		</div>
	);
}
