import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TAuthenticationState = {
	isLogin: boolean;
};
const initialState: TAuthenticationState = {
	isLogin: false,
};

export const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		changeLoginStatus: (state, action: PayloadAction<{ isLogin: boolean }>) => {
			state.isLogin = action.payload.isLogin;
		},
	},
});
