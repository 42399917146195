import { axiosRequest } from '@shared/api';
import { CvResponse, IDataCV } from '@models/cv';
import { downloadFile } from '@shared/lib/downloadFile';

export const generateFromForm = async ({
	language,
	nda,
	cv,
	fileName,
}: {
	language: 'en' | 'ru';
	nda?: boolean;
	cv: IDataCV;
	fileName: string;
}) => {
	const { data } = await axiosRequest<Blob>({
		method: 'POST',
		url: 'resume/generate/from-form',
		params: { language, nda },
		data: cv,
		responseType: 'blob',
	});
	downloadFile(fileName, data);
};
export const updateCv = async ({
	language,
	cvId,
	cv,
}: {
	language: 'en' | 'ru';
	cvId: string;
	cv: IDataCV;
}) => {
	await axiosRequest<Blob>({
		method: 'PUT',
		url: `/resume/${cvId}`,
		params: { language },
		data: cv,
	});
};
export const getCv = {
	key: '/resume',
	fetch: async (cvId: string) => {
		const { data } = await axiosRequest<CvResponse>({
			method: 'GET',
			url: `/resume/${cvId}`,
		});
		return data;
	},
};
export const generate = async ({
	userId,
	language,
	filename,
}: {
	userId: string;
	language: string;
	filename: string;
}) => {
	const { data } = await axiosRequest<Blob>({
		method: 'GET',
		url: `/resume/${userId}/generate`,
		responseType: 'blob',
		params: { language },
	});
	downloadFile(filename, data);
};

export const create = async () => {
	await axiosRequest({
		method: 'POST',
		url: '/resume',
	});
};
export const createRu = async (id: string) => {
	await axiosRequest({
		method: 'POST',
		url: `resume/${id}/generate-ru`,
	});
};
