import React, { useEffect } from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import { useLocale } from '@processes/app/lib';
import enUs from 'antd/lib/locale/en_US';
import ruRu from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import withProviders from '@app/providers';
import { NotificationComponent } from '@processes/notification';
import Pages from '@pages/index';
import { ModalComponent } from '@processes/modal';

const config: ThemeConfig = {
	token: {
		colorPrimary: '#1D7DE8',
		colorError: '#E53935',
		colorWarning: '#FFECB3',
		colorSuccess: '#0DAD40',
		colorInfo: '#333333',
	},
};
const localeAntd = {
	en: enUs,
	ru: ruRu,
};
function localeDayjs(locale: 'ru' | 'en') {
	dayjs.locale(locale);
}

const App = () => {
	const { locale } = useLocale();
	useEffect(() => {
		localeDayjs(locale);
	}, [locale]);
	return (
		<ConfigProvider theme={config} locale={localeAntd[locale]}>
			<>
				<Pages />
				<NotificationComponent />
				<ModalComponent />
			</>
		</ConfigProvider>
	);
};
export default withProviders(App);
