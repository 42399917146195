import React, { useEffect, useMemo } from 'react';
import styles from './style.module.scss';
import { Spin } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { EditCvModel } from '@features/edit-cv';
import FormHeader from './form-header';
import FormTabs from './form-tabs';
import { useAppStore } from '@processes/app/store';
import { useEditCv } from '@features/edit-cv/lib';
import { IDataCV } from '@models/cv';
import { Logger } from '@shared/services';
import { useBlocker } from '@shared/lib/useBlocker';
import { useIsEditForm } from '@entities/cv/lib';

const EditCvForm = () => {
	const { locale, language, guest } = useAppStore((state) => ({
		locale: state.locale,
		language: state.language,
		guest: state.guest,
	}));
	const localCv = localStorage.getItem(locale);

	const ChangeLocal = useAppStore((state) => state.ChangeLocal);
	const { showModal } = useIsEditForm();

	const { data, isLoading } = useEditCv();

	const form = useMemo(() => {
		if (guest && localCv) {
			try {
				const cv: IDataCV = JSON.parse(localCv);
				return new EditCvModel.CvFormDto(language, language === 'ru' ? cv?.ruCv : cv, cv?.filename);
			} catch (e) {
				Logger.error(e);
			}
		}
		return new EditCvModel.CvFormDto(
			language,
			language === 'ru' ? data?.ruCv : data,
			data?.filename,
		);
	}, [data, language, guest]);//eslint-disable-line

	const methods = useForm({
		mode: 'all',
		defaultValues: form,
	});
	const {
		reset,
		clearErrors,
		trigger,
		formState: { dirtyFields },
	} = methods;

	useEffect(() => {
		if (language === 'ru' && locale === 'en') {
			ChangeLocal('ru');
		}
		return () => {
			if (language === 'ru') ChangeLocal('en');
		};
	}, []);//eslint-disable-line

	useEffect(() => {
		reset(form);
		clearErrors();
	}, [form]);//eslint-disable-line

	useBlocker((tx) => {
		showModal(tx.retry);
	}, Object.values(dirtyFields).length > 0);

	useEffect(() => {
		(async () => {
			await trigger();
		})();
	}, [locale]);//eslint-disable-line

	return (
		<Spin spinning={isLoading}>
			<div className={styles.root}>
				<FormProvider {...methods}>
					<form>
						<FormHeader />
						<FormTabs />
					</form>
				</FormProvider>
			</div>
		</Spin>
	);
};

export default EditCvForm;
