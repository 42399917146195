import { useController, UseControllerProps } from 'react-hook-form';
import classNames from 'classnames';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

const DatePickerDayjs = generatePicker<Dayjs>(dayjsGenerateConfig);
type TProps = {
	main: {
		className?: string;
		label?: string;
		placeholder?: string;
		disabled?: boolean;
		picker?: 'time' | 'date' | 'week' | 'month' | 'year';
		format: string;
		extraFooter?: () => React.ReactNode;
	};
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
} & UseControllerProps<any>;

export function DatePickerField({ main, setValue, trigger, ...props }: TProps) {
	const { className: classProps, disabled, ...rest } = main;
	const {
		field: { value, name, onChange, ...restField },
		fieldState: { invalid, error },
	} = useController(props);

	const onChangeDate = (date: Dayjs | null, dateString: string | string[]) => {
		setValue(name, date);
		trigger(name);
	};

	return (
		<div className="form-item">
			{main.label && (
				<label className={classNames('label', { disabled: disabled })}>{main.label}</label>
			)}
			<DatePickerDayjs
				className={classNames(classProps)}
				id={name}
				name={name}
				disabled={disabled}
				status={invalid ? 'error' : ''}
				value={value ? value : null}
				onChange={onChangeDate}
				showNow={true}
				{...rest}
				{...restField}
				// @ts-ignore
				getPopupContainer={(trigger) => trigger.parentNode}
			/>
			{invalid && <div className="error-text">{error?.message}</div>}
		</div>
	);
}
