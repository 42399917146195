import React, { ChangeEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Input, Spin, Statistic } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthApi, AuthLib } from '@features/authentification';

const { Countdown } = Statistic;

type LocationState = {
	username: string;
};
const Email = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const username = (location.state as LocationState)?.username;

	const { loginInApplication } = AuthLib.useAuthentication();

	const [getCode, { isLoading, data, isSuccess }] = AuthApi.useSendOTPtoEmail();
	const [auth, { isLoading: isLoadingAuth, data: dataAuth, isSuccess: isSuccessAuth }] =
		AuthApi.useAuthenticationEmail();

	const [timer, setTimer] = useState<number | null>(null);
	const [value, setValue] = useState<string>('');

	const onFinish = () => {
		setTimer(null);
	};

	useEffect(() => {
		if (isSuccessAuth && dataAuth) {
			loginInApplication(dataAuth);
		}
	}, [isSuccessAuth]);//eslint-disable-line

	useEffect(() => {
		if (username && !isSuccess) getCode({ username });
	}, [username, isSuccess]);//eslint-disable-line

	useEffect(() => {
		if (data?.leftTime)
			setTimer(
				dayjs()
					.millisecond(data.leftTime * 1000)
					.valueOf(),
			);
	}, [data]);

	const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const onResendClick = () => {
		if (username) getCode({ username });
	};

	const onVerifyClick = () => {
		if (username && value) auth({ username, code: value });
	};

	const onCancelClick = () => {
		navigate('/authentication/login', { replace: true });
	};
	return (
		<Spin spinning={isLoading || isLoadingAuth}>
			<div className={styles.container}>
				<div className={styles.text}>
					Enter the code sent to your email {data?.email && data?.email}
				</div>
				<div className={classNames('form-item', styles.input)}>
					<Input value={value} onChange={onInputChange} placeholder={'code'} />
				</div>

				<div className={'flex flex-center'}>
					<Button
						onClick={onResendClick}
						type={'default'}
						loading={timer !== null}
						className={styles.timer}
					>
						{timer && <Countdown format={'ss'} value={timer} onFinish={onFinish} />}
						Resend
					</Button>
				</div>
				<div className={styles.btn}>
					<Button onClick={onVerifyClick} disabled={!value} type={'primary'}>
						Verify Code
					</Button>
					<Button onClick={onCancelClick} type={'primary'}>
						Cancel
					</Button>
				</div>
			</div>
		</Spin>
	);
};

export default Email;
