import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCv } from '@entities/cv/model/types';
import { getCvList } from './actions';

export type TCvState = {
	cvList: TCv[] | null;
	isLoading: boolean;
	error: any;
};
const initialState: TCvState = {
	cvList: null,
	isLoading: false,
	error: false,
};

export const CvSlice = createSlice({
	name: 'cv',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCvList.pending.type, (state) => {
				state.isLoading = true;
				state.cvList = null;
			})
			.addCase(getCvList.fulfilled.type, (state, action: PayloadAction<TCv[]>) => {
				state.isLoading = false;
				state.error = false;
				state.cvList = action.payload;
			})
			.addCase(getCvList.rejected.type, (state, action: PayloadAction<any>) => {
				state.isLoading = false;
				state.error = action.payload;
				state.cvList = null;
			});
	},
});
