import { RootState } from '@app/store';
import { createSelector } from '@reduxjs/toolkit';

const getState = (state: RootState) => state.cv;
export const isLead = createSelector(
	getState,
	(state) => !!state.cvList && state.cvList.length > 1,
);
export const error = createSelector(getState, (state) => state.error);
export const cvList = createSelector(getState, (state) => state.cvList);
export const cv = createSelector(getState, (state) => state.cvList && state.cvList[0]);
export const cvStatus = createSelector(getState, (state) => ({ isLoading: state.isLoading }));
