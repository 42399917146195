import { ModalCloseEvent, ModalOpenEvent } from '@processes/modal';
import WarningModal from '@shared/ui/warning-modal';
import React from 'react';

export function useIsEditForm() {
	const showModal = (forward: () => void) => {
		function leaveAndClose() {
			forward();
			ModalCloseEvent.Close();
		}
		ModalOpenEvent.Open({
			title: 'Exit without saving changes',
			component: <WarningModal closeModal={ModalCloseEvent.Close} leaveFunction={leaveAndClose} />,
		});
	};
	return {
		showModal,
	};
}
