export const fillingInstructionsEn = [
	{
		name: 'Past projects experience',
		text:
			'This section includes a listing of transitions from project to project in reverse order (starting with the latest). ' +
			'The information in the table includes' +
			'<ul>' +
			'<li>Name of the project (if it is possible to specify, if not — you can simply put “Web-application”, etc.).</li>' +
			'<li>Project period should be written in the format month.year (in the last project it is better to write the current month or “ongoing” so that there are no questions about the bench).</li>' +
			'<li>Short description.</li>' +
			'<li>Team composition (dev, qa, pm, ba, etc.) with the number of employees.</li>' +
			'<li>Position held in the company (while working on the project).</li>' +
			'<li>Project role (key/web/full stack/backend developer or QA).</li>' +
			'<li>Performed responsibilities.</li>' +
			'<li>Used technologies.</li>' +
			'</ul>' +
			'The description or title of projects should not include the words “Russian”, “Belarusian”, “government” and so on. It is better to put “NDA” in the project name and remove all words indicating its Russian origin from the project description (for example: Khanty-Mansi Autonomous Okrug-Yugra).' +
			'The dates on the projects, as well as their total duration, must match the number of years of experience written in the Basic Info table.' +
			'The list of projects should start from the most recent to the first. 7-10 project descriptions are enough for a very experienced employee, there is no point in writing more.',
	},
];
export const fillingInstructionsRu = [
	{
		name: 'Прошлые проекты',
		text:
			'В этот раздел включается последовательность перехода с проекта на проект в обратном порядке (начиная с последнего).\n' +
			'Информация в таблице включает в себя:\n' +
			'Наименование проекта (если есть возможность указать, если нет - можно просто Web-application и т.п.).\n' +
			'Период работы на проекте писать в формате месяц.год (в последнем проекте писать лучше текущий месяц или что все еще на проекте, чтобы не было вопросов про лавку).\n' +
			'Краткое описание.\n' +
			'Состав команды (dev, qa, pm, ba и т.п.) с указанием количества сотрудников.\n' +
			'Занимаемая должность в компании (во время работы на проекте).\n' +
			'Проектная роль (key/web/fullstack/backend developer или QA).\n' +
			'Выполняемые обязанности.\n' +
			'Использованные технологии.\n' +
			'В описании или названии проектов не следует употреблять слова «российский», «белорусский», «government» и подобные. Лучше указывать в названии проектов NDA, а из описания проекта убирать все слова, указывающие на их российское происхождение (например: Khanty-Mansi Autonomous Okrug-Yugra). \n' +
			'Даты в проектах, а также их суммарная длительность должны соответствовать количеству лет опыта, указанному в таблице Basic Info.\n' +
			'Перечень проектов необходимо начинать с самого последнего до первого. При большом опыте сотрудника достаточно 7-10 описаний проектов, больше писать нет смысла.',
	},
];
