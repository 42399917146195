import { FC, useState } from 'react';
import './style.scss';
import { Outlet } from 'react-router-dom';
import Header from '@widgets/header';
import Footer from '@widgets/footer';
import Sidebar from '@widgets/sidebar';
import SecondaryHeader from '@shared/ui/secondary-header';
import engFlag from '@app/assets/images/eng-flag.svg';
import ruFlag from '@app/assets/images/ru-flag.svg';
import useGetSearchParams from '@shared/lib/useGetSearchParams';
import { AppModel } from '@processes/app';

const text = {
	Profile: {
		en: 'Profile',
		ru: 'Профиль',
	},
	Editing: {
		en: 'Editing the profile for',
		ru: 'Редактирование профиля для',
	},
};
const MainTemplate: FC = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const handleToggleClick = () => setIsSidebarOpen((prevState) => !prevState);
	const { isProfile, userName } = useGetSearchParams();
	const { locale, language } = AppModel.useAppStore((state) => ({
		locale: state.locale,
		language: state.language,
	}));
	return (
		<div className="main-template">
			<div className="main-template__header-wrapper">
				<Header isSidebarOpen={isSidebarOpen} handleToggleClick={handleToggleClick} />
			</div>

			<div className="main-template__sidebar-content-wrapper">
				<div className="main-template__sidebar-wrapper">
					<Sidebar isSidebarOpen={isSidebarOpen} handleToggleClick={handleToggleClick} />
				</div>

				<div className="main-template__content-wrapper">
					<div className="main-template__secondary-header-wrapper">
						<div className="secondary-header-left">
							<SecondaryHeader title={text.Profile[locale]} cvBelong="" />
							{isProfile && language && (
								<img alt="flag" src={language === 'en' ? engFlag : ruFlag} />
							)}
						</div>
						<div className="secondary-header-right">
							{userName && (
								<>
									<span className="secondary-header-right-title">{text.Editing[locale]}: </span>
									<span>{userName ?? ''}</span>
								</>
							)}
						</div>
					</div>

					<main className="main-template__outlet-wrapper">
						<Outlet />
					</main>

					<div className="main-template__footer-wrapper">
						<Footer />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainTemplate;
