export const fillingInstructionsEn = [
	{
		name: 'Languages',
		text: 'Specify languages and their proficiency level. We indicate knowledge of only foreign languages (do not indicate knowledge of Russian / Belarusian / Polish).',
	},
];
export const fillingInstructionsRu = [
	{
		name: 'Языки',
		text: 'Указываются языки и уровни владения ими. Указываем знание только иностранных языков (владение русским/белорусским/польским языком не указывать).',
	},
];
