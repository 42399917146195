import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Button, Input, Spin } from 'antd';
import { AuthApi, AuthLib } from '@features/authentification';

type LocationState = {
	username: string;
};
const App = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const username = (location.state as LocationState)?.username;

	const { loginInApplication } = AuthLib.useAuthentication();

	const [auth, { isLoading, data, isSuccess }] = AuthApi.useAuthenticationApp();
	const [value, setValue] = useState<string>('');

	useEffect(() => {
		if (isSuccess && data) {
			loginInApplication(data);
		}
	}, [isSuccess]);//eslint-disable-line


	const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const onVerifyClick = () => {
		if (username && value) auth({ username, code: value });
	};

	const onCancelClick = () => {
		navigate('/authentication/login', { replace: true });
	};

	return (
		<Spin spinning={isLoading}>
			<div className={styles.container}>
				<div className={styles.text}>
					<strong>TOTP (Authenticator app)</strong> <br />
					Get the authentication code from the two factor authentication app on your device.
				</div>
				<div className={classNames('form-item', styles.input)}>
					<Input value={value} onChange={onInputChange} placeholder={'Authenticator code'} />
				</div>

				<div className={styles.btn}>
					<Button onClick={onVerifyClick} disabled={!value} type={'primary'}>
						Verify Code
					</Button>
					<Button onClick={onCancelClick} type={'primary'}>
						Cancel
					</Button>
				</div>
			</div>
		</Spin>
	);
};

export default App;
