const subscription = {} as { [key: string]: any };

const subscribe = (eventName: string, callback: Function) => {
	if (!subscription[eventName]) {
		subscription[eventName] = new Set();
	}
	const callbacks = subscription[eventName];
	callbacks.add(callback);
	return () => {
		callbacks.delete(callback);

		if (callbacks.size === 0) {
			delete subscription[eventName];
		}
	};
};
const broadcast = (eventName: string, ...args: any) => {
	if (!subscription[eventName]) {
		return;
	}
	const callbacks = subscription[eventName];
	for (const callback of callbacks) {
		callback(...args);
	}
};
const events = {
	subscribe,
	broadcast,
};
export default events;
