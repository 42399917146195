import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TUser } from '../types';
import { NotificationEvent } from '@processes/notification';
import $api from '@shared/api';

export const getUserInfo = createAsyncThunk(
	'authentication/get-user-info',
	async (_data, thunkAPI) => {
		try {
			const { data: user } = await $api.get<TUser>('user/user-info');
			return user;
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				NotificationEvent.open({
					type: 'error',
					info: { message: error.message },
				});
				return thunkAPI.rejectWithValue(error.message);
			} else return thunkAPI.rejectWithValue('Что-то не так');
		}
	},
);
