import $api from '@shared/api/index';

export class TokenService {
	static updateTokens(accessToken?: string, refreshToken?: string, time?: number): void {
		localStorage.setItem('accessToken', accessToken ? accessToken : '');
		localStorage.setItem('refreshToken', refreshToken ? refreshToken : '');
		localStorage.setItem('time', `${time}`);
	}
	static clearTokens(): void {
		localStorage.setItem('accessToken', '');
		localStorage.setItem('refreshToken', '');
		localStorage.setItem('time', '');
	}
	static checkTokens(): boolean {
		return !!(localStorage.getItem('accessToken') && localStorage.getItem('refreshToken'));
	}
	static async refresh() {
		const token = {
			accessToken: localStorage.getItem('accessToken'),
			refreshToken: localStorage.getItem('refreshToken'),
		};
		return $api.post('auth/refresh', token);
	}
}
