import { CopySvg } from '@app/assets/svg';
import React, { FC } from 'react';
import styles from './style.module.scss';
import { NotificationEvent } from '@processes/notification';

type TCopyClipboard = {
	content: string;
};

const CopyClipboard: FC<TCopyClipboard> = ({ content }) => {
	return (
		<div className={styles.clipboard}>
			<b>{content}</b>
			<div
				onClick={() => {
					NotificationEvent.open({
						type: 'info',
						info: { message: 'Copied to clipboard' },
					});
					navigator.clipboard.writeText(content);
				}}
			>
				<CopySvg />
			</div>
		</div>
	);
};

export default CopyClipboard;
