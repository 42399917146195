import { FC } from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';
import { ArrowRoundedSvg } from '@app/assets/svg';
import { useLocale } from '@processes/app/lib';
import { FileOutlined } from '@ant-design/icons';

const links = [
	{
		text: 'Profile generator',
		textRu: 'Генератор профиля',
		to: '/',
		icon: <FileOutlined />,
	},
	// {
	// 	text: 'Company hierarchy',
	// 	to: '/hierarchy',
	// 	icon: <HierarchySvg />,
	// },
	// {
	// 	text: 'My profile',
	// 	to: '/profile',
	// 	icon: <UserSvg />,
	// },
];

interface ISidebar {
	isSidebarOpen: boolean;
	handleToggleClick: () => void;
}

const Sidebar: FC<ISidebar> = ({ isSidebarOpen, handleToggleClick }) => {
	const { locale } = useLocale();
	return (
		<div className={`sidebar ${isSidebarOpen ? 'opened' : ''}`}>
			<div className="sidebar__list">
				{links.map(({ text, icon, to, textRu }) => (
					<NavLink
						key={locale === 'en' ? text : textRu}
						to={to}
						className={({ isActive }) => (isActive ? 'sidebar__link active' : 'sidebar__link')}
					>
						<span className="sidebar__link-icon">{icon}</span>
						<span className="sidebar__link-text">{locale === 'en' ? text : textRu}</span>
					</NavLink>
				))}
			</div>

			<button className="sidebar__toggle-button" onClick={handleToggleClick}>
				<span className={`sidebar__toggle-icon ${isSidebarOpen ? 'opened' : ''}`}>
					<ArrowRoundedSvg />
				</span>
			</button>
		</div>
	);
};

export default Sidebar;
