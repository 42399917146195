import { FC } from 'react';
import styles from './style.module.scss';
import { SaveSvg } from '@app/assets/svg';
import { Button } from 'antd';

interface IWarningModal {
	closeModal?: () => void;
	leaveFunction?: () => void;
}

const WarningModal: FC<IWarningModal> = ({ closeModal, leaveFunction }) => {
	return (
		<div className={styles.modal}>
			<div className={styles.info}>
				<SaveSvg />
				<span>You didn't click the Save button. Do you want to exit without saving changes?</span>
			</div>
			<div className={styles.buttons}>
				<Button type="primary" onClick={leaveFunction}>
					Leave
				</Button>
				<Button onClick={closeModal}>Continue editing</Button>
			</div>
		</div>
	);
};

export default WarningModal;
