import React, { FC } from 'react';
import styles from './style.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField } from '@shared/ui/form-items';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import { useAppStore } from '@processes/app/store';
const { Text } = Typography;

export const text = {
	en: 'This field is required',
	ru: 'Поле обязательно к заполнению',
};
const translation = {
	certificate: {
		ru: 'Название сертификата',
		en: 'Certificate name',
	},
	placeholder_certificate: {
		ru: 'Введите название сертификата',
		en: 'Enter certificate name',
	},
	hint: {
		ru: 'Поле должно быть заполнено',
		en: 'Field must be completed',
	},
	add: {
		ru: 'Добавить',
		en: 'Add',
	},
	save: {
		ru: 'Изменить',
		en: 'Ok',
	},
	cancel: {
		ru: 'Закрыть',
		en: 'Cancel',
	},
};
type Form = {
	modal?: never;
	closeModal?: never;
	onSetValue: Function;
	defaultValue?: string;
};
type FormInModal = {
	modal: boolean;
	closeModal: () => void;
	onSetValue: (value: string) => void;
	defaultValue: string;
};
type Props = Form | FormInModal;

const ValidateInputs = (required: string, locale: string) => {
	return {
		required: required,
		validate: (value: any) => {
			if (value.trim() === '')
				return locale === 'en'
					? 'You cannot insert only Spaces'
					: 'Нельзя вставлять только пробелы';
			else if (value.charAt(0) === ' ') {
				return locale === 'en'
					? "You can't start typing with a Space"
					: 'Нельзя начинать слово с пробела';
			}
		},
	};
};

const FormComponent: FC<Props> = ({ modal, closeModal, onSetValue, defaultValue }) => {
	const locale = useAppStore((state) => state.locale);
	const methods = useForm({
		mode: 'onChange',
		defaultValues: {
			name: defaultValue ?? '',
		},
	});
	const {
		formState: { isValid, errors },
		control,
		getValues,
		reset,
		handleSubmit,
	} = methods;

	const onButtonClick = () => {
		if (isValid) {
			onSetValue(getValues().name);
			reset();
		}
	};

	const isDisabled = !!errors.name;

	return (
		<div className={classNames(styles.form, { modal: modal })}>
			<FormProvider {...methods}>
				<InputField
					main={{
						label: translation.certificate[locale],
						placeholder: translation.placeholder_certificate[locale],
					}}
					name={'name'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<div className={styles.footer}>
					<Text>{translation.hint[locale]}</Text>
					<Button
						title={isDisabled ? translation.hint[locale] : ''}
						disabled={isDisabled}
						type={'primary'}
						onClick={handleSubmit(onButtonClick)}
						className={classNames(styles.submit)}
					>
						{modal ? translation.save[locale] : translation.add[locale]}
					</Button>
					{modal && (
						<Button onClick={closeModal} type="default">
							{translation.cancel[locale]}
						</Button>
					)}
				</div>
			</FormProvider>
		</div>
	);
};

export default FormComponent;
