import React, { FC, useLayoutEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '@shared/model';
import { AuthModel } from '@features/authentification';
import { TokenService } from '@shared/api/token';
import { AxiosError } from 'axios';
import { useActionCreators } from '@shared/model/hooks';
import { UserModel } from '@entities/user';
import { CvModel } from '@entities/cv';

const RequiredAuth: FC<{ children: React.ReactNode }> = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const isLogin = useAppSelector(AuthModel.selectors.isLogin);
	const AuthenticationActions = useActionCreators(AuthModel.actions);
	const UserActions = useActionCreators(UserModel.actions);
	const CvModelActions = useActionCreators(CvModel.actions);

	useLayoutEffect(() => {
		if (!isLogin && TokenService.checkTokens()) {
			TokenService.refresh()
				.then(({ data }) => {
					TokenService.updateTokens(data.accessToken, data.refreshToken, data.expiresAt);
					AuthenticationActions.changeLoginStatus({ isLogin: true });
					UserActions.getUserInfo();
					CvModelActions.getCvList();
				})
				.catch((_error: AxiosError) => {
					TokenService.clearTokens();
					AuthenticationActions.changeLoginStatus({ isLogin: false });
					navigate('/authentication/login', { replace: true });
				});
		}
	}, [isLogin]);//eslint-disable-line

	if (!isLogin && !TokenService.checkTokens()) {
		return <Navigate to={'/authentication/login'} state={{ from: location }} />;
	}

	return <>{children}</>;
};
export { RequiredAuth };
