import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TUser } from '../types';

import { IUser } from '@models/user';
import { getUserInfo } from './actions';
import { TokenService } from '@shared/api/token';

export type TUserState = {
	user: TUser | null;
	isLoading: boolean;
	error: any;
};
const initialState: TUserState = {
	user: null,
	isLoading: false,
	error: null,
};

export const userSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		logout: (state) => {
			state.user = null;
			TokenService.clearTokens();
		},
		cancelAuth: (_state) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserInfo.pending.type, (state) => {
				state.isLoading = true;
			})
			.addCase(getUserInfo.fulfilled.type, (state, action: PayloadAction<IUser>) => {
				state.isLoading = false;
				state.user = action.payload;
			})
			.addCase(getUserInfo.rejected.type, (state, action: PayloadAction<any>) => {
				state.error = action.payload;
				state.isLoading = false;
			});
	},
});
