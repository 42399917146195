import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '@shared/api';
import axios, { AxiosResponse } from 'axios';
import { TCv } from '@entities/cv/model/types';
import { NotificationEvent } from '@processes/notification';

export const getCvList = createAsyncThunk('cv/list', async (_arg, thunkAPI) => {
	try {
		const { data } = await $api.get<AxiosResponse<{ data: TCv[] }>>('/resume');
		return data.data;
	} catch (error: unknown) {
		if (axios.isAxiosError(error)) {
			NotificationEvent.open({
				type: 'error',
				info: { message: error.message },
			});
			return thunkAPI.rejectWithValue(error.message);
		} else return thunkAPI.rejectWithValue('Что-то не так');
	}
});
