export default function InnerHTML(text: string) {
	if (!text) return { __html: text };
	const regexp = /<[^>]*script/g;
	const newText = text.replace(regexp, '');
	return { __html: newText };
}
export function InnerHTMLWithBr(text: string) {
	if (!text) return { __html: text };
	const regexp = /\n/g;
	const newText = text.replace(regexp, '<br />');
	return { __html: newText };
}
