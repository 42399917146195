import React from 'react';
import styles from './style.module.scss';
import { Button, Spin, Tooltip, Typography } from 'antd';
import eng from '@app/assets/images/eng-flag.svg';
import ru from '@app/assets/images/ru-flag.svg';
import cardFooter from '@app/assets/images/card-footer.svg';
import { DownloadSvg, EditSvg } from '@app/assets/svg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { CvModel } from '@entities/cv';
import { getDate } from '@shared/lib/getDateString';
import { useActionCreators, useAppSelector } from '@shared/model/hooks';
import { useAppStore } from '@processes/app/store';
import { useMutation } from 'react-query';
import { CvApi } from '@entities/cv';
import CvCardCustom from '@entities/cv/ui/cv-card-custom';

const { Text, Title } = Typography;

type TCard = {
	lang: 'ru' | 'en';
	cv?: CvModel.TCv | null;
};

const text = {
	en: {
		hint: 'First you need to fill out a Profile in English',
	},
	ru: {
		hint: 'Сначала необходимо заполнить резюме на английском',
	},
};

const CvCard: React.FC<TCard> = ({ lang, cv }) => {
	const SetCvLanguage = useAppStore((state) => state.SetCvLanguage);
	const isEnLang = lang === 'en';
	const isRuLang = lang === 'ru';
	const isLead = useAppSelector(CvModel.selectors.isLead);

	const navigate = useNavigate();
	const locale = useAppStore((state) => state.locale);
	const actions = useActionCreators(CvModel.actions);

	const { mutate: createCv, isLoading: isLoadingCreate } = useMutation({
		mutationFn: CvApi.create,
		onSuccess: () => {
			//todo change to react-query
			actions.getCvList();
		},
	});
	const { mutate: createRuCv, isLoading: isLoadingCreateRu } = useMutation({
		mutationFn: CvApi.createRu,
		onSuccess: () => {
			//todo change to react-query
			actions.getCvList();
		},
	});
	const isLoading = [isLoadingCreate, isLoadingCreateRu].some(Boolean);
	const { mutate: cvDownload, isLoading: isLoadingDownload } = useMutation({
		mutationFn: CvApi.generate,
	});

	function onCreateCv() {
		if (isEnLang) {
			createCv();
		} else {
			cv?.userDataId && createRuCv(cv.userDataId);
		}
	}

	function onCvDownload() {
		if (cv) {
			const fileName = isEnLang
				? cv.filename + ' [' + getDate(cv.dateUpdated) + ']_en'
				: cv.filename + ' [' + getDate(cv.dateUpdatedRu) + ']_ru';
			cvDownload({ filename: fileName, userId: cv.userDataId, language: lang });
		}
	}

	function onOpenCv() {
		if (cv) {
			SetCvLanguage(lang);
			navigate(`/cv/${cv?.userDataId}`);
		}
	}

	const validateDisabled = () => {
		return isRuLang && !cv?.userDataId;
	};

	const date = getDate(isEnLang ? cv?.dateUpdated : cv?.dateUpdatedRu);

	return (
		<div className={classNames(styles.cv_card)}>
			<Spin spinning={isLoading}>
				<div className={classNames(styles.cv_card_inner)}>
					{isRuLang && cv && isLead && (
						<div className={styles.cv_card_custom}>
							<CvCardCustom cv={cv} />
						</div>
					)}
					{cv?.userId && (
						<Title className={classNames(styles.cv_card_title, { hidden: !cv.filename })} level={5}>
							{cv.filename && `${cv.filename}.docx`}
						</Title>
					)}
					<Text className={styles.cv_card_date}>{!!date && `Updated: ${date}`}</Text>
					<div className={styles.cv_card_info}>
						<Text>My Profile</Text>
						<div className={styles.cv_card_flag}>
							<img alt={'flag'} src={isEnLang ? eng : ru} />
						</div>
						<Text className={styles.cv_card_lang}>{isEnLang ? 'In English' : 'На Русском'}</Text>
						<img alt={'footer'} src={cardFooter} />
					</div>
					<div className={styles.cv_card_buttons}>
						{(isEnLang && cv?.userDataId) || (isRuLang && cv?.cvRuIsGenerated) ? (
							<>
								<Spin spinning={isLoadingDownload}>
									<Tooltip title={'Download'}>
										<Button className={styles.cv_card_download} onClick={onCvDownload}>
											<DownloadSvg />
										</Button>
									</Tooltip>
								</Spin>
								<Tooltip title={'Edit Cv'}>
									<Button onClick={onOpenCv} className={styles.cv_card_edit}>
										<EditSvg />
									</Button>
								</Tooltip>
								{/*{isRuLang && (*/}
								{/*	<div className={styles.cv_card_custom}>*/}
								{/*		<CvCardCustom cv={cv} />*/}
								{/*	</div>*/}
								{/*)}*/}
							</>
						) : (
							<Button
								className={styles.cv_card_create}
								type={'primary'}
								loading={isLoading}
								disabled={validateDisabled()}
								onClick={onCreateCv}
							>
								{locale === 'en'
									? `Create Profile (${isRuLang ? 'Ru' : 'Eng'})`
									: `Создать профиль (${isRuLang ? 'Ru' : 'Eng'})`}
							</Button>
						)}
					</div>
				</div>
				{isRuLang && !cv?.cvRuIsGenerated && (
					<div className={classNames(styles.cv_card_footer)}>
						<Text className={classNames(styles.cv_card_hint)}>{text[locale].hint}</Text>
					</div>
				)}
			</Spin>
		</div>
	);
};

export default CvCard;
