import React, { FC, useState } from 'react';
import styles from './style.module.scss';
import { DragIndicatorSvg } from '@app/assets/svg';
import classNames from 'classnames';
import EditItem from '@shared/ui/edit-item';
import { ReactSortable } from 'react-sortablejs';
import { useFormContext } from 'react-hook-form';
import { removeSkill, removeSkillChildren, setSkillsChildren } from '../lib';
import { EditCvModel } from '@features/edit-cv';
type Props = {
	skills: EditCvModel.TSkillsForm;
	handler: (newState: EditCvModel.TSkillsForm, shouldDirty: boolean) => void;
	categoryId: string;
	showModal: (idSkillChildren: string) => void;
};
const ChildrenList: FC<Props> = ({ skills, handler, categoryId, showModal }) => {
	const [isDragging, setIsDragging] = useState<boolean | null>(null);
	const { setValue, getValues } = useFormContext<EditCvModel.CvFormDto>();
	const onDragHandler = (newState: EditCvModel.TSkillsForm) => {
		handler(newState, typeof isDragging === 'boolean');
	};
	const onDelete = (indexItem: string) => () => {
		const newChildrenArray = removeSkillChildren(skills, indexItem);
		const skillsList = getValues().skills;
		if (newChildrenArray.length === 0) {
			setValue(`skills`, removeSkill(skillsList, categoryId), { shouldDirty: true });
		} else {
			setValue('skills', setSkillsChildren(skillsList, newChildrenArray, categoryId), {
				shouldDirty: true,
			});
		}
	};
	return (
		<div>
			<ReactSortable
				list={skills}
				setList={onDragHandler}
				animation={250}
				handle={'.handle-sub'}
				onStart={() => setIsDragging(true)}
				onUnchoose={() => setIsDragging(false)}
			>
				{skills.map(({ id, item: { name, level } }) => (
					<div className={styles.item} key={id}>
						<DragIndicatorSvg className={classNames('handle-sub', styles.dragIcon)} />
						<div className={styles.name}>{name}</div>
						<div className={styles.level}>{level}</div>
						<EditItem onEdit={() => showModal(id)} onDelete={onDelete(id)} />
					</div>
				))}
			</ReactSortable>
		</div>
	);
};

export default ChildrenList;
