import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { UserModel } from '@entities/user';
import { CvModel } from '@entities/cv';
import { AuthApi, AuthModel } from '@features/authentification';

const rootReducer = combineReducers({
	authentication: AuthModel.reducer,
	user: UserModel.reducer,
	cv: CvModel.reducer,
	[AuthApi.Api.reducerPath]: AuthApi.Api.reducer,
});
export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat([AuthApi.Api.middleware]),
		devTools: process.env.REACT_APP_BUILD === 'dev',
	});
};
export type RootState = ReturnType<typeof rootReducer>;
