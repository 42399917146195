const closeCircle =
	'<svg viewBox="64 64 896 896" data-icon="close-circle" width="0.7em" height="0.8em" fill="grey" aria-hidden="true" style="margin: 0 3px;"' +
	'><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg>';
export const fillingInstructionsEn = [
	{
		name: 'Skills',
		text:
			'This section includes details of technical skills with an indication of the level of proficiency. \n' +
			'The levels are:\n' +
			'<ul>' +
			'<li>Novice.</li>' +
			'<li>Intermediate.</li>' +
			'<li>Advanced.</li>' +
			'<li>Expert.</li>' +
			'</ul>' +
			'The skills in the table are divided into categories — for better customer’s perception. The information must be grouped by categories.<br/>' +
			'<br/>' +
			'You can select a skill by searching for it in the "Skill" selection box, in which case the group will be selected automatically. Or first select a category in the "Category" selection box and then select a skill in the "Skill" selection box from that category.<br/>' +
			'<br/>' +
			'In case of editing current skill, user should click' +
			closeCircle +
			'next to skill to unlock the list of the categories.<br/>' +
			'<br/>' +
			'In groups order is important: first indicate what is relevant to your technology in order of importance (for example, programming languages, frameworks, databases, tools for work, etc. for developers). For different technologies the order may differ, for example, it won’t be the same for design and front-end. <br/>' +
			'If an employee has a Middle level of knowledge, then the levels of technology proficiency can be: Novice/Intermediate/Advanced. For profile knowledge you need to indicate Advanced level.<br/>' +
			'If the employee has a Senior level, then all profile skills must be at the Expert level. <br/>' +
			'<br/>' +
			'!!! If you think that any skill is missing from the list, or it is in a wrong category, inform your Teamleader about it.',
	},
];
export const fillingInstructionsRu = [
	{
		name: 'Навыки',
		text:
			'В этот раздел включается детализация технических навыков с указанием уровня владения. \n' +
			'Уровни бывают:\n' +
			'<ul>' +
			'<li>Начинающий.</li>' +
			'<li>Средний.</li>' +
			'<li>Продвинутый.</li>' +
			'<li>Эксперт.</li>' +
			'</ul>' +
			'Навыки в таблице разбиты на категории — для лучшего восприятия заказчиком. Информация по навыкам должна быть сгруппирована по категориям.<br/>' +
			'<br/>' +
			'Вы можете выбрать навык используя поиск в поле выбора "Навык", в этом случае группа выберется автоматически. Либо сначала выбрать категорию в поле выбора "Категория", а затем навык в поле выбора "Навык" из данной категории.<br/>' +
			'<br/>' +
			'В случае редактирования существующего навыка, пользователь должен нажать' +
			closeCircle +
			'рядом с навыком для разблокирования списка категорий.<br/>' +
			'<br/>' +
			'В группах важна последовательность: первыми надо указывать то, что относиться к вашей технологии по степени важности (к примеру, языки програмирования, фреймворки, БД, инструменты для работы и т.д. для разработчиков). Для разных технологий последовательность может отличаться, к примеру у дизайна и фронтенда - будет разная. <br/>' +
			'Если сотрудник имеет уровень знаний Middle, то уровни владения технологиями могут быть: Novice/Intermediate/Advanced. Для профильных знаний необходимо указывать уровень Advanced.<br/>' +
			'Если сотрудник уровня Senior, то все профильные навыки обязательно должны быть уровня Expert.<br/>' +
			'<br/>' +
			'!!! Если вы считаете что какого-то навыка не хватает в списке, либо он находиться не в той категории сообщите об этом своему руководителю.',
	},
];
