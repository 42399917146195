import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { useLocale } from '@processes/app/lib';
import { LogoSvg } from '@app/assets/svg';
import styles from './styles.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@shared/model';
import { AuthModel } from '@features/authentification';
import { SelectLocale } from '@processes/app/ui';

const { Title, Text } = Typography;

const email = 'admin@senla.eu';

const text = {
	en: {
		hint: 'If you have any problem, please contact ',
	},
	ru: {
		hint: 'Если у вас возникли проблемы, обращайтесь ',
	},
};

const Template = () => {
	const { locale } = useLocale();
	const isLogin = useAppSelector(AuthModel.selectors.isLogin);
	const navigate = useNavigate();

	useEffect(() => {
		if (isLogin) {
			navigate('/', { replace: true });
		} else {
			const path = window.location.pathname.split('/');
			if (!path[2]) {
				navigate('/authentication/login');
			}
		}
	}, [isLogin]);//eslint-disable-line

	return (
		<div className={styles.authentication}>
			<div className={styles.inner} style={{ backgroundImage: 'url("/images/login.svg")' }}>
				<div className={styles.lang}>
					<SelectLocale />
				</div>
				<div className={styles.title}>
					<div className={styles.title__line}></div>
					<Title>Profile Generator</Title>
					<div className={styles.title__line}></div>
				</div>
				<div className={styles.form}>
					<div className={styles.form__inner}>
						<LogoSvg className={styles.logo} />
						<Outlet />
					</div>
				</div>
				<div className={styles.footer}>
					<Text>
						{text[locale].hint}
						<b>{email}</b>
					</Text>
				</div>
			</div>
		</div>
	);
};

export default Template;
