import React, { FC } from 'react';
import styles from './styles.module.scss';
import { CompanyLogoShortSvg, CompanyLogoSvg } from '@app/assets/svg';
import { SelectLocale } from '@processes/app/ui';
import HamburgerIcon from '@shared/ui/hamburger-btn';
import { UserInfo } from '@entities/user';
import { useNavigate } from 'react-router-dom';

type Props = {
	isSidebarOpen: boolean;
	handleToggleClick: () => void;
};

const Header: FC<Props> = ({ isSidebarOpen, handleToggleClick }) => {
	const navigate = useNavigate();

	function onLogoClick() {
		navigate('/');
	}

	return (
		<header className={styles.header}>
			<div className={styles.header__content}>
				<div className={styles.header__hamburger}>
					<HamburgerIcon isActive={isSidebarOpen} handleClick={handleToggleClick} />
				</div>
				{window.innerWidth > 1024 && (
					<CompanyLogoSvg onClick={onLogoClick} className={styles.logo} />
				)}
				{window.innerWidth <= 1024 && (
					<CompanyLogoShortSvg onClick={onLogoClick} className={styles.logo} />
				)}
				<div className={styles.header__right}>
					<div className={styles.select}>
						<SelectLocale />
					</div>
					<UserInfo />
				</div>
			</div>
		</header>
	);
};

export default Header;
