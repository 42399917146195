import React, { MouseEvent, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { CvCard, CvListItem } from '@entities/cv';
import { Button, Input, List, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAppSelector } from '@shared/model';
import { CvModel } from '@entities/cv';
import { SortAlphabetSvg, SortNotAlphabetSvg } from '@app/assets/svg';
import { usePagination } from '@pages/cv/teamlead/lib';
import { CvUserModel } from '@features/cv-user';
import { useLocale } from '@processes/app/lib';

const { Text } = Typography;

const text = {
	en: {
		title: "Employee Profile's",
		placeholder: 'Search for an employee by first and last name',
	},
	ru: {
		title: 'Резюме сотрудников',
		placeholder: 'Поиск сотрудника по имени и фамилии',
	},
};

const TeamLeadPage = () => {
	const [sort, setSort] = useState<'ASC' | 'DEC'>('ASC');
	const [search, setSearch] = useState('');
	const { handleChange, size, current, pageSizeOptions } = usePagination();
	const cv = CvUserModel.useGetCvUser();
	const { locale } = useLocale();

	const cvList = CvUserModel.useGetCvWithoutUser();
	const { isLoading } = useAppSelector(CvModel.selectors.cvStatus);

	const list = useMemo(() => {
		if (!cvList) return [];
		let list = [...cvList];
		if (search) {
			list = cvList.filter((cv) => cv.username.toLowerCase().indexOf(search.toLowerCase()) >= 0);
		}
		if (sort === 'DEC') {
			list.reverse();
		}
		return list;
	}, [cvList, search, sort]);

	function onSort(_event: MouseEvent<HTMLElement>) {
		setSort((prevState) => (prevState === 'ASC' ? 'DEC' : 'ASC'));
	}

	return (
		<div className={styles.teamlead}>
			<div className={styles.cards}>
				{!cv && <Text className={styles.title}>You haven't created any of your CVs</Text>}
				<CvCard lang="en" cv={cv} />
				<CvCard lang="ru" cv={cv} />
			</div>
			<div className={styles.list}>
				<div className={styles.filters}>
					<Button className={styles.sort} onClick={onSort}>
						{sort === 'ASC' ? <SortAlphabetSvg /> : <SortNotAlphabetSvg />}
					</Button>
					<div className={styles.search}>
						<Input
							name={'search'}
							onChange={(event) => setSearch(event.target.value)}
							placeholder={text[locale].placeholder}
							prefix={<SearchOutlined />}
							allowClear
						/>
					</div>
				</div>
				<Text className={styles.list_title}>{`${text[locale].title} (${list.length})`}</Text>
				<div className={styles.list_items}>
					<List
						dataSource={list}
						loading={isLoading}
						pagination={
							list?.length > 10 && {
								pageSizeOptions,
								position: 'bottom',
								current,
								pageSize: size,
								onChange: handleChange,
								className: styles.pagination,
								showSizeChanger: true,
								total: cvList?.length,
								showTotal: (total, _range) =>
									locale === 'en'
										? `Showing ${current} to ${size} of ${total} employees`
										: `Отображено от ${current} до ${size} из ${total} сотрудников`,
							}
						}
						renderItem={(cv) => <CvListItem key={cv.userId} {...cv} />}
					/>
				</div>
			</div>
		</div>
	);
};

export default TeamLeadPage;
