import { v4 as uuidv4 } from 'uuid';
import { CvModel } from '@entities/cv';
import { EditCvModel } from '@features/edit-cv';
type TSkill = { name: string; level: string; category: string };
export function addNewSkill({
	name,
	level,
	category,
}: TSkill): EditCvModel.ItemType<EditCvModel.TSkillsForm>[] {
	return [
		{
			id: category,
			item: [
				{
					id: uuidv4(),
					item: { name, level },
				},
			],
		},
	];
}

export function addSkillGroup(
	skills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[],
	{ name, level, category }: TSkill,
): EditCvModel.ItemType<EditCvModel.TSkillsForm>[] {
	const newSkills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[] = [...skills];
	const categoryIndex = newSkills.findIndex((el) => el.id === category);
	if (categoryIndex < 0) {
		newSkills.push({ id: category, item: [{ id: uuidv4(), item: { name, level } }] });
	} else {
		newSkills[categoryIndex].item.push({ id: uuidv4(), item: { level, name } });
	}
	return newSkills;
}

export function removeSkill(
	skills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[],
	categoryId: string,
): EditCvModel.ItemType<EditCvModel.TSkillsForm>[] {
	const newSkills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[] = [...skills];
	const categoryIndex = newSkills.findIndex((el) => el.id === categoryId);
	newSkills.splice(categoryIndex, 1);
	return newSkills;
}

export function removeSkillChildren(
	skills: EditCvModel.TSkillsForm,
	id: string,
): EditCvModel.TSkillsForm {
	const newSkills: EditCvModel.TSkillsForm = [...skills];
	const childrenIndex = newSkills.findIndex((el) => el.id === id);
	newSkills.splice(childrenIndex, 1);
	return newSkills;
}

export function setSkillsChildren(
	skills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[],
	skillsChildren: EditCvModel.TSkillsForm,
	categoryId: string,
): EditCvModel.ItemType<EditCvModel.TSkillsForm>[] {
	const newSkills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[] = [...skills];
	const categoryIndex = newSkills.findIndex((el) => el.id === categoryId);
	newSkills[categoryIndex].item = skillsChildren;
	return newSkills;
}

export function onDragChildren(
	skills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[],
	skillsChildren: EditCvModel.TSkillsForm,
	category: string,
): EditCvModel.ItemType<EditCvModel.TSkillsForm>[] {
	const newSkills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[] = [...skills];
	const categoryIndex = newSkills.findIndex((el) => el.id === category);
	newSkills[categoryIndex].item = skillsChildren;
	return newSkills;
}

export function findSkill(
	skills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[],
	category: string,
	index: string,
): ({ index: string } & CvModel.TSkills) | null {
	const findGroup = skills.find((skillGroup) => skillGroup.id === category);
	if (findGroup) {
		const findSkill = findGroup.item.find((skill) => skill.id === index);
		if (findSkill) {
			return { index, category, level: findSkill.item.level, name: findSkill.item.name };
		}
		return null;
	}
	return null;
}

export function updateSkills(
	skills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[],
	skillInfo: { category: string; id: string },
	skillModal: { index: string } & CvModel.TSkills,
): EditCvModel.ItemType<EditCvModel.TSkillsForm>[] {
	let newSkills: EditCvModel.ItemType<EditCvModel.TSkillsForm>[] = [...skills];

	if (skillModal.category === skillInfo.category) {
		const findGroupIndex = newSkills.findIndex(
			(skillGroup) => skillGroup.id === skillModal.category,
		);
		const findChildrenIndex = newSkills[findGroupIndex].item.findIndex(
			(skill) => skill.id === skillModal.index,
		);
		newSkills[findGroupIndex].item[findChildrenIndex].item.name = skillModal.name;
		newSkills[findGroupIndex].item[findChildrenIndex].item.level = skillModal.level;
	} else {
		const findGroupIndex = newSkills.findIndex(
			(skillGroup) => skillGroup.id === skillInfo.category,
		);
		const deleteInChildren = removeSkillChildren(newSkills[findGroupIndex].item, skillInfo.id);

		if (deleteInChildren.length === 0) {
			newSkills = removeSkill(newSkills, skillInfo.category);
		} else {
			newSkills[findGroupIndex].item = deleteInChildren;
		}

		newSkills = addSkillGroup(newSkills, {
			name: skillModal.name,
			level: skillModal.level,
			category: skillModal.category,
		});
	}
	return newSkills;
}
