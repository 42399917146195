import React from 'react';
import styles from './style.module.scss';
import { CvCard } from '@entities/cv';
import { Typography } from 'antd';
import classNames from 'classnames';
import { useLocale } from '@processes/app/lib';
import { CvModel } from '@entities/cv';
import { useAppSelector } from '@shared/model';
import { HelloText } from '@entities/user';

const { Text } = Typography;

const mockSubTitle = {
	ru: 'Вы еще не создали профиль. Не откладывайте и создайте его, чтобы ваш профиль можно было разослать клиентам',
	en: "You have not created a profile yet. Don't delay and create it so your profile can be sent to customers",
};

const EmployeePage = () => {
	const { locale } = useLocale();
	const cv = useAppSelector(CvModel.selectors.cv);

	return (
		<div className={styles.employee}>
			<div className={styles.inner}>
				<div className={classNames(styles.header)}>
					<HelloText />
					{!cv?.userDataId && <Text className={styles.subtitle}>{mockSubTitle[locale]}</Text>}
				</div>
				<div className={styles.cards}>
					<CvCard lang="en" cv={cv} />
					<CvCard lang="ru" cv={cv} />
				</div>
			</div>
		</div>
	);
};

export default EmployeePage;
