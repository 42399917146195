import { useCallback, useState } from 'react';
const pageSizeOptions = [10, 20, 30, 50];
export function usePagination() {
	const [size, setSize] = useState(10);
	const [current, setCurrent] = useState(1);

	const handleChange = useCallback((page: number, pageSize: number) => {
		setCurrent(page);
		setSize(pageSize);
	}, []);

	return { size, handleChange, current, pageSizeOptions };
}
