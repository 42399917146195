import { EmailCode, TotpCode } from '@app/assets/svg';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

type TType = 'email' | 'totp';
type LocationState = {
	username: string;
};
const TwoFactorForm = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const username = (location.state as LocationState)?.username;

	useEffect(() => {
		if (!username) {
			navigate('/authentication/login');
		}
	}, [username]);//eslint-disable-line

	const onTypeClick = (type: TType) => (_event: any) => {
		if (type === 'email') navigate('/authentication/email', { state: location.state });
		if (type === 'totp') navigate('/authentication/app', { state: location.state });
	};

	return (
		<div className={styles.card__list}>
			<div className={styles.titleLarge}>Chose two-factor authentication</div>
			<div className={styles.card} onClick={onTypeClick('email')}>
				<EmailCode className={styles.icon} />
				<div className={styles.text}>
					<span>Email verification</span>
					<small>Send a code to your email address</small>
				</div>
			</div>
			<div className={styles.card} onClick={onTypeClick('totp')}>
				<TotpCode className={styles.icon} />
				<div className={styles.text}>
					<span>TOTP (Authenticator app)</span>
					<small>Authenticate with a TOTP app</small>
				</div>
			</div>
		</div>
	);
};

export default TwoFactorForm;
