import React from 'react';
import styles from './style.module.scss';
import { useAppSelector } from '@shared/model';
import { selectors } from '@entities/user/model';

const HelloText = () => {
	const user = useAppSelector(selectors.user);
	if (!user) return null;
	return <h3 className={styles.title}>Hello, {user.username}!</h3>;
};

export default HelloText;
