import React, { useEffect } from 'react';
import { useAppSelector } from '@shared/model';
import { CvModel } from '@entities/cv';
import TeamLeadPage from '@pages/cv/teamlead';
import EmployeePage from '@pages/cv/employee';
import { Spin } from 'antd';
import { AuthModel } from '@features/authentification';
import { Navigate } from 'react-router-dom';
import { useActionCreators } from '@shared/model/hooks';

const CvPage = () => {
	const isLogin = useAppSelector(AuthModel.selectors.isLogin);
	const isError = useAppSelector(CvModel.selectors.error);
	const isLead = useAppSelector(CvModel.selectors.isLead);
	const { isLoading } = useAppSelector(CvModel.selectors.cvStatus);
	const actions = useActionCreators(CvModel.actions);

	useEffect(() => {
		if (isLogin) {
			actions.getCvList();
		}
	}, [isLogin]); //eslint-disable-line

	if (isError) {
		return <Navigate to={'/error'} />;
	}

	if (isLead && !isLoading) {
		return (
			<Spin spinning={isLoading}>
				<TeamLeadPage />
			</Spin>
		);
	}
	return (
		<Spin spinning={isLoading}>
			<EmployeePage />
		</Spin>
	);
};

export default CvPage;
