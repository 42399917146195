import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthApi, AuthLib, AuthModel } from '..';

export function useLogin() {
	const { loginInApplication } = AuthLib.useAuthentication();
	const [login, { isLoading, isSuccess, data }] = AuthApi.useLogin();
	const navigate = useNavigate();
	useEffect(() => {
		if (isSuccess && data) {
			if (typeof data.accessToken === 'string') {
				loginInApplication(data as AuthModel.TResponseToken);
			} else {
				if (data.usingEmail2fa && data.usingTotp2fa) {
					navigate('/authentication/2fe', { state: { username: data.username } });
				} else if (data.usingEmail2fa) {
					navigate('/authentication/email', { state: { username: data.username } });
				} else if (data.usingTotp2fa) {
					navigate('/authentication/app', { state: { username: data.username } });
				}
			}
		}
    }, [isSuccess, data]);//eslint-disable-line

	return { login, isLoading };
}
