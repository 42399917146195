export const fillingInstructionsEn = [
	{
		name: 'Certification',
		text:
			'Optional section. Which is only filled in where certificates are a mandatory requirement.' +
			' If it is not mandatory for you, but you have relevant/profile certificates, please check with your team leader whether they should be included.',
	},
];
export const fillingInstructionsRu = [
	{
		name: 'Сертификаты',
		text:
			'Необязательный раздел, который заполняет только там где сертификаты являются обязательным требованием.' +
			'Если для вас это не обязательно, но у вас есть релевантные/профильные сертификаты уточните у тимлида стоит ли их вносить.\n',
	},
];
