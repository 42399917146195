import React, { useRef, useState } from 'react';
import styles from './style.module.scss';
import GroupLabel from '@shared/ui/group-label';
import { Collapse } from 'antd';
import { useFieldArray } from 'react-hook-form';
import InnerHTML from '@shared/lib/inner-html';
import { v4 as uuidv4 } from 'uuid';
import FormComponent from './form';
import List from './list';
import InstructionsWrapper from '../instructions-wrapper';
import { useLocale } from '@processes/app/lib';
import { fillingInstructionsEn, fillingInstructionsRu } from './text';
import { EditCvModel } from '@features/edit-cv';

const translation = {
	add_project: {
		ru: 'Добавить проект',
		en: 'Add project experience',
	},
};

const Projects = () => {
	const { locale } = useLocale();
	const fillingInstructions = locale === 'en' ? fillingInstructionsEn : fillingInstructionsRu;
	const instructionRef = useRef<HTMLInputElement>(null);
	const [activeKeys, setActiveKeys] = useState<string[]>([]);
	const activateCollapsePanel = (activeKey: string) => {
		if (!activeKeys.includes(activeKey)) {
			setActiveKeys((prevState) => [...prevState, activeKey]);
		}
		instructionRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	const { prepend } = useFieldArray({ name: 'pastProjects' });

	const onAdd = ({
		periodStart,
		periodEnd,
		dateOnGoing,
		...rest
	}: EditCvModel.TPastProjectExpForm) => {
		prepend({
			id: uuidv4(),
			item: {
				...rest,
				periodStart: periodStart.format('MM.YYYY'),
				periodEnd: !dateOnGoing ? (periodEnd ? periodEnd.format('MM.YYYY') : '') : '',
				dateOnGoing: dateOnGoing,
			},
		});
	};

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.add_section}>
					<div className={styles.add_title}>
						<GroupLabel
							handleClick={() => activateCollapsePanel(fillingInstructions[0].name)}
							text={translation.add_project[locale]}
						/>
					</div>
					<FormComponent onSetValue={onAdd} />
				</div>
				<List />
			</div>
			<div ref={instructionRef}>
				<InstructionsWrapper>
					<Collapse
						className="filling-instructions"
						activeKey={activeKeys}
						onChange={(key) => setActiveKeys(key as string[])}
						ghost
						items={[
							{
								key: fillingInstructions[0].name,
								label: fillingInstructions[0].name,
								children: <p dangerouslySetInnerHTML={InnerHTML(fillingInstructions[0].text)} />,
							},
						]}
					></Collapse>
				</InstructionsWrapper>
			</div>
		</div>
	);
};

export default Projects;
