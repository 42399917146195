import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { TokenService } from '@shared/api/token';
import { useActionCreators } from '@shared/model/hooks';
import { UserModel } from '@entities/user';
import { AuthModel } from '..';

export function useLogout() {
	const navigate = useNavigate();
	const actionsAuth = useActionCreators(AuthModel.actions);
	const actionsUser = useActionCreators(UserModel.actions);

	const logout = useCallback(() => {
		actionsUser.logout();
		actionsAuth.changeLoginStatus({ isLogin: false });
		TokenService.clearTokens();
		navigate('/authentication/login');
	}, []);//eslint-disable-line

	return { logout };
}
