import React, { useEffect } from 'react';
import { notification } from 'antd';
import { NotificationType } from '@models/notification';
import InnerHTML from '@shared/lib/inner-html';
import { NotificationEvent } from '@processes/notification/index';

const Notification = () => {
	const [api, contextHolder] = notification.useNotification();
	const openNotification = ({ type, info }: NotificationType) => {
		api[type]({
			message: info.message,
			description: info?.description && (
				<div dangerouslySetInnerHTML={InnerHTML(info.description)} />
			),
		});
	};
	useEffect(() => {
		const unsubscribe = NotificationEvent.subscribe(openNotification);
		return () => unsubscribe();
		// eslint-disable-next-line
	}, []);

	return <>{contextHolder}</>;
};
export default Notification;
