import { CvModel } from '@entities/cv';
import { UserModel } from '@entities/user';
import { useAppSelector } from '@shared/model';
import { useMemo } from 'react';

export function useGetCvUser() {
	const user = useAppSelector(UserModel.selectors.user);
	const cvList = useAppSelector(CvModel.selectors.cvList);
	return useMemo(() => {
		if (!user) return null;
		if (!cvList?.length) return null;
		return cvList.find((cv) => cv.userId === user.id);
	}, [user, cvList]);
}
export function useGetCvWithoutUser() {
	const user = useAppSelector(UserModel.selectors.user);
	const cvList = useAppSelector(CvModel.selectors.cvList);
	return useMemo(() => {
		if (!user) return [];
		if (!cvList?.length) return [];
		return cvList.filter((cv) => cv.userId !== user.id);
	}, [user, cvList]);
}
