import React, { useRef, useState } from 'react';
import styles from './style.module.scss';
import GroupLabel from '@shared/ui/group-label';
import { Collapse } from 'antd';
import { useFormContext } from 'react-hook-form';
import FormComponent from './form';
import List from './list';
import { CvModel } from '@entities/cv';
import InnerHTML from '@shared/lib/inner-html';
import { fillingInstructionsEn, fillingInstructionsRu } from './text';
import { addNewSkill, addSkillGroup } from './lib';
import InstructionsWrapper from '../instructions-wrapper';
import { useLocale } from '@processes/app/lib';
import { EditCvModel } from '@features/edit-cv';

const translation = {
	add_skill: {
		ru: 'Добавить навык',
		en: 'Add skill',
	},
};

const Skills = () => {
	const { setValue, getValues } = useFormContext<EditCvModel.CvFormDto>();
	const { locale } = useLocale();
	const fillingInstructions = locale === 'en' ? fillingInstructionsEn : fillingInstructionsRu;
	const instructionRef = useRef<HTMLInputElement>(null);
	const [activeKeys, setActiveKeys] = useState<string[]>([]);
	const activateCollapsePanel = (activeKey: string) => {
		if (!activeKeys.includes(activeKey)) {
			setActiveKeys((prevState) => [...prevState, activeKey]);
		}
		instructionRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const onAdd = (value: CvModel.TSkills) => {
		const skillList = getValues().skills;
		const length = skillList.length;
		if (length) {
			setValue('skills', addSkillGroup(skillList, value));
		} else {
			setValue(`skills`, addNewSkill(value));
		}
	};

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.add_section}>
					<div className={styles.add_title}>
						<GroupLabel
							handleClick={() => activateCollapsePanel(fillingInstructions[0].name)}
							text={translation.add_skill[locale]}
						/>
					</div>
					<FormComponent onSetValue={onAdd} />
				</div>
				<List />
			</div>
			<div ref={instructionRef}>
				<InstructionsWrapper>
					<Collapse
						className="filling-instructions"
						activeKey={activeKeys}
						onChange={(key) => setActiveKeys(key as string[])}
						ghost
						items={[
							{
								key: fillingInstructions[0].name,
								label: fillingInstructions[0].name,
								children: <p dangerouslySetInnerHTML={InnerHTML(fillingInstructions[0].text)} />,
							},
						]}
					></Collapse>
				</InstructionsWrapper>
			</div>
		</div>
	);
};

export default Skills;
