import React, { FC, useEffect } from 'react';
import styles from './style.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SelectField } from '@shared/ui/form-items';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import { CvModel } from '@entities/cv';
import { EditCvModel } from '@features/edit-cv';
import { AppModel } from '@processes/app';
import { SkillsList } from '@features/edit-cv/model/skills';
const { Text } = Typography;

export const text = {
	en: 'This field is required',
	ru: 'Поле обязательно к заполнению',
};
const translation = {
	skill: {
		ru: 'Навык',
		en: 'Skill',
	},
	placeholder_skill: {
		ru: 'Введите название навыка',
		en: 'Enter skill name',
	},
	level: {
		ru: 'Уровень',
		en: 'Level',
	},
	placeholder_level: {
		ru: 'Выберите уровень',
		en: 'Choose level',
	},
	category: {
		ru: 'Категория',
		en: 'Category',
	},
	placeholder_category: {
		ru: 'Выберите категорию',
		en: 'Choose category',
	},
	hint: {
		ru: 'Все поля должны быть заполнены',
		en: 'All fields must be completed',
	},
	add: {
		ru: 'Добавить',
		en: 'Add',
	},
	save: {
		ru: 'Изменить',
		en: 'Ok',
	},
	cancel: {
		ru: 'Закрыть',
		en: 'Cancel',
	},
};
type Form = {
	modal?: never;
	closeModal?: never;
	onSetValue: Function;
	defaultValue?: never;
};
type FormInModal = {
	modal: boolean;
	closeModal: () => void;
	onSetValue: (value: CvModel.TSkills) => void;
	defaultValue: CvModel.TSkills;
};
type Props = Form | FormInModal;

const ValidateInputs = (required: string, locale: string) => {
	return {
		required: required,
		validate: (value: any) => {
			if (value.trim() === '')
				return locale === 'en'
					? 'You cannot insert only Spaces'
					: 'Нельзя вставлять только пробелы';
			else if (value.charAt(0) === ' ') {
				return locale === 'en'
					? "You can't start typing with a Space"
					: 'Нельзя начинать слово с пробела';
			}
		},
	};
};

const FormComponent: FC<Props> = ({ modal, closeModal, onSetValue, defaultValue }) => {
	const { locale, language } = AppModel.useAppStore((state) => ({
		locale: state.locale,
		language: state.language,
	}));

	const SkillLevelsList =
		language === 'ru'
			? EditCvModel.SkillLevelsRu.map((el) => ({ label: el, value: el }))
			: EditCvModel.SkillLevels.map((el) => ({ label: el, value: el }));

	const SkillCategoriesList =
		language === 'ru'
			? EditCvModel.SkillCategoriesRu.map((el) => ({ label: el, value: el }))
			: EditCvModel.SkillCategories.map((el) => ({ label: el, value: el }));

	const skillsList = SkillsList;
	const skillsListArray = SkillsList.flat()
		.map((el) => ({ label: el, value: el }))
		.sort((a, b) => a.label.localeCompare(b.label));

	const methods = useForm<CvModel.TSkills>({
		mode: 'onChange',
		defaultValues: defaultValue || { name: '', level: '', category: '' },
	});
	const {
		formState: { isValid, errors },
		control,
		getValues,
		reset,
		handleSubmit,
		setValue,
		trigger,
		watch,
	} = methods;

	const skillChanged = watch('name');
	const categoryChanged = watch('category');

	useEffect(() => {
		if (skillChanged) {
			const findIndex = skillsList.findIndex((arr) => arr.includes(skillChanged));
			if (findIndex > -1) {
				setValue('category', SkillCategoriesList[findIndex].value);
				trigger('category');
			}
		}
	}, [skillChanged]);// eslint-disable-line

	function getSkillListByCategory() {
		if (categoryChanged) {
			const oneCategorySkills =
				skillsList[SkillCategoriesList.findIndex((item) => item.value === getValues('category'))];
			if (oneCategorySkills) {
				return oneCategorySkills.map((el) => ({ label: el, value: el }));
			} else {
				return skillsListArray;
			}
		} else {
			return skillsListArray;
		}
	}

	const onButtonClick = () => {
		if (isValid) {
			onSetValue(getValues());
			reset();
		}
	};
	const isDisabled = !!errors.name || !!errors.level || !!errors.category;
	return (
		<div className={classNames(styles.form, { modal: modal })}>
			<FormProvider {...methods}>
				<SelectField
					main={{
						label: translation.skill[locale],
						placeholder: translation.placeholder_skill[locale],
						options: getSkillListByCategory(),
						showSearch: true,
						allowClear: true,
					}}
					setValue={setValue}
					trigger={trigger}
					name={'name'}
					rules={{ required: text[locale] }}
				/>
				{/*<InputField*/}
				{/*	main={{*/}
				{/*		label: translation.skill[locale],*/}
				{/*		placeholder: translation.placeholder_skill[locale],*/}
				{/*		allowClear: true,*/}
				{/*	}}*/}
				{/*	name={'name'}*/}
				{/*	control={control}*/}
				{/*	rules={ValidateInputs(text[locale], locale)}*/}
				{/*/>*/}
				<SelectField
					main={{
						label: translation.level[locale],
						placeholder: translation.placeholder_level[locale],
						options: SkillLevelsList,
						allowClear: true,
					}}
					setValue={setValue}
					trigger={trigger}
					name={'level'}
					rules={{ required: text[locale] }}
				/>
				<SelectField
					main={{
						label: translation.category[locale],
						placeholder: translation.placeholder_category[locale],
						options: SkillCategoriesList,
						showSearch: true,
						allowClear: true,
						disabled: !!getValues('name'),
					}}
					setValue={setValue}
					trigger={trigger}
					name={'category'}
					rules={{ required: text[locale] }}
				/>
				<div className={styles.footer}>
					<Text>{translation.hint[locale]}</Text>
					<Button
						title={isDisabled ? translation.hint[locale] : ''}
						disabled={isDisabled}
						type={'primary'}
						onClick={handleSubmit(onButtonClick)}
						className={classNames(styles.submit)}
					>
						{modal ? translation.save[locale] : translation.add[locale]}
					</Button>
					{modal && (
						<Button onClick={closeModal} type="default">
							{translation.cancel[locale]}
						</Button>
					)}
				</div>
			</FormProvider>
		</div>
	);
};

export default FormComponent;
