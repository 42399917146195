import { FC } from 'react';
import './style.scss';

interface ISecondaryHeader {
	title: string;
	cvBelong: string;
}

const SecondaryHeader: FC<ISecondaryHeader> = ({ title, cvBelong }) => {
	return (
		<div className="secondary-header">
			<span className="secondary-header__title">{title}</span>
			<span className="secondary-header__cv-belong">{cvBelong}</span>
		</div>
	);
};

export default SecondaryHeader;
