export const fillingInstructionsEn = [
	{
		name: 'Introduction',
		text:
			'The first block of the Employee Profile for the customer is Introduction. It should consist of 1-2 paragraphs that tell about:' +
			'<ul>' +
			'<li>Soft skills of an employee: teamwork, communication skills, initiative, ability to resolve conflict situations, mentoring, etc.</li>' +
			'<li>Hard skills: in what technical direction he develops, what projects he finds interesting, what complex and modern tasks the employee solved on recent projects.</li>' +
			'<li>Interesting facts/events/experience (mostly technical). For example: working with microserver architecture, frameworks, system integrations, tools, etc.</li>' +
			'<li>Work on flexible methodologies and roles on projects, knowledge of CI/CD principles.</li>' +
			'<li>Study of new technologies, colleague training.</li>' +
			'</ul>' +
			'<strong>IMPORTANT:</strong> it is forbidden to include the dates of education — both in the description and in general in the profile. In general, it is better not to include any dates so that there are no problems in the future.',
	},
	{
		name: 'Basic info',
		text:
			'This section includes the following information:' +
			'<ul>' +
			'<li>name,</li>' +
			'<li>surname (on request, usually not indicated),</li>' +
			'<li>position,</li>' +
			'<li>primary skill (Java/React/QA),</li>' +
			'<li>total years of experience in the IT field.</li>' +
			'</ul>' +
			'<strong>IT’S FORBIDDEN</strong> to indicate less than 3 years of experience for <strong>Middle level employees</strong> (descriptions of at least 3 projects; from the description of the first projects it should be clear that the employee worked there as a <strong>Junior</strong> specialist).\n' +
			'For a Senior level employee, you need to specify 5+ years of experience (sometimes 4.5+ is acceptable).  \n' +
			'\n' +
			`<br/> <strong>IMPORTANT:</strong> Profile's of employees with less experience are not even considered by customers!`,
	},
];
export const fillingInstructionsRu = [
	{
		name: 'Введение',
		text:
			'Первый блок Employee Profile для заказчика — Introduction (Введение). Он должен состоять из 1-2 абзацев текста, в котором рассказывается о:' +
			'<ul>' +
			'<li>Soft skills сотрудника: работа в команде, коммуникативные навыки, инициативность, умение решать конфликтные ситуации, менторство и т.д.</li>' +
			'<li>Hard skills сотрудника: в каком техническом направлении развивается, какие проекты интересны, какие сложные и современные задачи решал ' +
			'сотрудник на последних проектах.</li>' +
			'<li>Интересные факты/события/опыт (в основном технические). Например: работа с микросерверной архитектурой, фреймворками, системные ' +
			'интеграции, инструменты и т.д.</li>' +
			'<li>Работа по гибким методологиям и роли на проектах, знания CI/CD принципов.</li>' +
			'<li>Изучение новых технологий, тренинги, обучение коллег.</li>' +
			'</ul>' +
			'<strong>ВАЖНО:</strong> запрещено указывать даты получения образования — как в описании, так и вообще в резюме. В целом, не надо указывать никакие ' +
			'даты, чтобы не было проблем в дальнейшем.',
	},
	{
		name: 'Основная информация',
		text:
			'В этот раздел включается следующая информация:' +
			'<ul>' +
			'<li>имя,</li>' +
			'<li>фамилия (по требованию, обычно не указывается),</li>' +
			'<li>должность,</li>' +
			'<li>основной навык (Java/React/QA),</li>' +
			'<li>общий опыт в сфере IT в годах.</li>' +
			'</ul>' +
			'<strong>ЗАПРЕЩЕНО</strong> указывать менее 3 лет опыта сотрудникам уровня <strong>Middle</strong> (указаны описания не менее 3 проектов; из описания первых проектов должно быть понятно, что сотрудник работал там в роли <strong>Junior</strong> специалиста).\n' +
			'Для сотрудника уровня Senior необходимо указать 5+ лет опыта (иногда допустимо  4,5+). \n' +
			'\n' +
			'<br/> <strong>ВАЖНО:</strong> резюме сотрудников с меньшим опытом заказчики даже не рассматривают!',
	},
];
export const text = {
	en: 'This field is required',
	ru: 'Поле обязательно к заполнению',
};
