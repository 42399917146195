import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import styles from './styles.module.scss';
import { ModalType } from './events/open';
import { ModalCloseEvent, ModalOpenEvent } from '@processes/modal/index';

const ModalContent: FC<{ isOpen: boolean; data: ModalType | null; closeModal: Function }> = ({
	isOpen,
	data,
	closeModal,
}) => {
	const handleCancel = () => {
		closeModal();
	};

	return (
		<Modal
			className={styles.modal}
			title={data?.title}
			centered
			open={isOpen}
			onCancel={handleCancel}
			footer={null}
			destroyOnClose
		>
			{data?.component}
		</Modal>
	);
};
const ModalComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState<ModalType | null>(null);
	const OpenModal = (data: ModalType) => {
		setIsOpen(true);
		setData(data);
	};
	const CloseModal = () => {
		setIsOpen(false);
		setData(null);
	};

	useEffect(() => {
		const unsubscribe = ModalOpenEvent.subscribe(OpenModal);
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const unsubscribe = ModalCloseEvent.subscribe(CloseModal);
		return () => unsubscribe();
	}, []);

	return <ModalContent isOpen={isOpen} data={data} closeModal={CloseModal} />;
};
export default ModalComponent;
