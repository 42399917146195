import { useController, UseControllerProps } from 'react-hook-form';
import { Input } from 'antd';
import classNames from 'classnames';
import { TextAreaProps } from 'antd/es/input';

type TProps = {
	main: { label?: string } & TextAreaProps;
} & UseControllerProps<any>;

export function TextAreaField({ main, ...props }: TProps) {
	const { className: classProps, disabled, ...rest } = main;
	const {
		field,
		fieldState: { invalid, error },
	} = useController(props);

	return (
		<div className="form-item">
			{main.label && (
				<label className={classNames('label', { disabled: disabled })} htmlFor={field.name}>
					{main.label}
				</label>
			)}
			<Input.TextArea
				autoComplete={main.autoComplete ?? 'off'}
				className={classNames(classProps, 'textArea')}
				placeholder={main.placeholder}
				id={field.name}
				disabled={disabled}
				status={invalid ? 'error' : ''}
				autoSize={true}
				{...rest}
				{...field}
			/>
			{invalid && <div className="error-text">{error?.message}</div>}
		</div>
	);
}
