import { useController, UseControllerProps } from 'react-hook-form';
import { Checkbox, CheckboxProps } from 'antd';
import classNames from 'classnames';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type TProps = {
	main: { label?: string } & CheckboxProps;
	handler?: (event: CheckboxChangeEvent) => void;
} & UseControllerProps<any>;

export function CheckboxField({ main, ...props }: TProps) {
	const { className: classProps, disabled, ...rest } = main;
	const {
		field: { value, name, onChange, ...restField },
		fieldState: { invalid, error },
	} = useController(props);

	const onHandlerChange = (event: CheckboxChangeEvent) => {
		props?.handler && props?.handler(event);
		onChange(event);
	};

	return (
		<div className="form-item">
			{main.label && (
				<label className={classNames('label', { disabled: disabled })}>{main.label}</label>
			)}
			<Checkbox
				className={classNames(classProps)}
				id={name}
				name={name}
				checked={value}
				// status={invalid ? 'error' : ''}
				onChange={onHandlerChange}
				{...rest}
				{...restField}
			/>
			{invalid && <div className="error-text">{error?.message}</div>}
		</div>
	);
}
