import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCv } from '@entities/cv/api';
import { useEffect } from 'react';

export function useEditCv() {
	const location = useLocation();
	const params = useParams();
	const navigate = useNavigate();

	const { data, isLoading } = useQuery({
		queryKey: [getCv.key, params?.cvId],
		queryFn: () => getCv.fetch(params?.cvId as string),
		enabled: !!params?.cvId,
		onError: () => {
			navigate('/404');
		},
	});

	useEffect(() => {
		const isGuest = location.pathname === '/guest';
		if (!isGuest && !params?.cvId) navigate('/', { replace: true });
	}, [params]);//eslint-disable-line



	return {
		data,
		isLoading,
		cvId: params.cvId,
	};
}
