import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Popconfirm, Spin, Typography } from 'antd';
import styles from './styles.module.scss';
import { CloseSvg } from '@app/assets/svg';
import CopyClipboard from '@shared/ui/copy-clipboard';
import { InputField, InputPasswordField } from '@shared/ui/form-items';
import { AuthLib, AuthModel, ModalAccesses } from '@features/authentification';
import { useAppStore } from '@processes/app/store';
import { ModalOpenEvent } from '@processes/modal';
import { AppModel } from '@processes/app';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;
const text = {
	username: {
		ru: 'Имя пользователя',
		en: 'Username',
	},
	password: {
		ru: 'Пароль',
		en: 'Password',
	},
	btn: {
		ru: 'Войти',
		en: 'Log in',
	},
	forgotPassword: {
		ru: 'Забыли пароль?',
		en: 'Forgot password?',
	},
	problem: {
		ru: 'Если вы забыли пароль или у вас возникли другие проблемы, обращайтесь:',
		en: 'If you forgot your password or have other problems, please contact:',
	},
	guest: {
		ru: 'Гость',
		en: 'Guest User',
	},
};

const email = 'admin@senla.eu';
const LoginForm = () => {
	const navigate = useNavigate();
	const guestHasAccesses = AppModel.useAppStore((state) => state.guestHasAccesses);
	const locale = useAppStore((state) => state.locale);
	const { login, isLoading } = AuthLib.useLogin();

	const methods = useForm<AuthModel.TCredentials>({
		mode: 'all',
		defaultValues: {
			username: '',
			password: '',
		},
	});
	const {
		handleSubmit,
		trigger,
		formState: { isValid, isSubmitted, touchedFields },
		setFocus,
		control,
	} = methods;

	useEffect(() => {
		if (Object.keys(touchedFields).length) {
			trigger();
		}
	}, [locale]);//eslint-disable-line

	const onSubmit = (data: AuthModel.TCredentials) => {
		if (isValid) {
			login(data);
		} else {
			setFocus('username');
		}
	};

	const onModalGuest = () => {
		if (guestHasAccesses) {
			navigate('/guest');
		} else {
			ModalOpenEvent.Open({
				title: '',
				component: <ModalAccesses />,
			});
		}
	};

	return (
		<FormProvider {...methods}>
			<Spin spinning={isLoading}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.form}>
						<InputField
							main={{
								placeholder: text.username[locale],
								label: text.username[locale],
								allowClear: true,
							}}
							name={'username'}
							control={control}
							rules={{
								required: {
									message: locale === 'ru' ? 'Поле обязательно' : 'Field required',
									value: true,
								},
								maxLength: {
									message:
										locale === 'ru'
											? 'Максимальное количество символов 50'
											: 'Maximum number of characters is 50',
									value: 50,
								},
							}}
						/>
						<InputPasswordField
							main={{ placeholder: text.password[locale], label: text.password[locale] }}
							name={'password'}
							control={control}
							rules={{
								minLength: {
									message:
										locale === 'ru'
											? 'Минимальное количество символов 5'
											: 'Minimum number of characters is 5',
									value: 5,
								},
								maxLength: {
									message:
										locale === 'ru'
											? 'Максимальное количество символов 20'
											: 'Maximum number of characters is 20',
									value: 20,
								},
								required: {
									message: locale === 'ru' ? 'Поле обязательно' : 'Field required',
									value: true,
								},
							}}
						/>
						<div className={styles.footer}>
							<div className={styles.footer__text} onClick={onModalGuest}>
								{text.guest[locale]}
							</div>
							<Popconfirm
								overlayClassName={'hint-popup'}
								okButtonProps={{ hidden: true }}
								cancelText={<CloseSvg />}
								icon={null}
								title={
									<>
										<Text>
											{text.problem[locale]}
											<br />
											<div className={styles.copy}>
												<CopyClipboard content={email} />
											</div>
										</Text>
									</>
								}
							>
								<div className={styles.footer__text}>{text.forgotPassword[locale]}</div>
							</Popconfirm>
						</div>
					</div>
					<div className={styles.form__button}>
						<Button type={'primary'} htmlType={'submit'} disabled={!isSubmitted ? false : !isValid}>
							{text.btn[locale]}
						</Button>
					</div>
				</form>
			</Spin>
		</FormProvider>
	);
};

export default LoginForm;
