import { FC } from 'react';
import { InfoOutlineSvg } from '@app/assets/svg';
import './style.scss';

interface IGroupLabelProps {
	text: string;
	handleClick: () => void;
	className?: string;
}

const GroupLabel: FC<IGroupLabelProps> = ({ text, handleClick, className }) => {
	return (
		<button
			type="button"
			onClick={handleClick}
			className={`group-label ${className ? className : ''}`}
		>
			<span className="group-label__text">{text}</span>
			<span className="group-label__icon">
				<InfoOutlineSvg />
			</span>
		</button>
	);
};

export default GroupLabel;
