import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

type Locale = 'en' | 'ru';

type Store = {
	locale: Locale;
	language: Locale;
	guest: boolean;
	guestHasAccesses: boolean;
};
type Actions = {
	ChangeLocal: (locale: Locale) => void;
	SetCvLanguage: (cv: Locale) => void;
	SetGuest: (value: boolean) => void;
	SetGuestAccesses: (value: boolean) => void;
	ResetField: (field: keyof Store) => void;
};

const initialState: Store = {
	// eslint-disable-next-line
	locale: <Locale>sessionStorage.getItem('locale') || 'en',
	// eslint-disable-next-line
	language: <Locale>sessionStorage.getItem('language') || 'en',
	guest: false,
	guestHasAccesses: false,
};

export const useAppStore = createWithEqualityFn<Store & Actions>()(
	devtools(
		immer((set) => ({
			...initialState,
			ChangeLocal: (locale) =>
				set((state) => {
					state.locale = locale;
					sessionStorage.setItem('locale', locale);
				}),
			SetCvLanguage: (language) =>
				set((state) => {
					state.language = language;
					sessionStorage.setItem('language', language);
				}),
			SetGuest: (guset) =>
				set((state) => {
					state.guest = guset;
				}),
			SetGuestAccesses: (accesses) =>
				set((state) => {
					state.guestHasAccesses = accesses;
				}),
			ResetField: (field) =>
				set((state) => {
					// @ts-ignore
					state[field] = initialState[field];
				}),
		})),
	),
);
