import { TRoutes } from '@shared/types';
import { LoginForm, EmailForm, AppForm, TwoFactorForm } from '@features/authentification';
import React from 'react';
import Template from './template';

export const AuthenticationRoutes: TRoutes[] = [
	{
		path: 'authentication',
		element: <Template />,
		children: [
			{
				path: '/authentication/login',
				element: <LoginForm />,
			},
			{
				path: '/authentication/2fe',
				element: <TwoFactorForm />,
			},
			{
				path: '/authentication/email',
				element: <EmailForm />,
			},
			{
				path: '/authentication/app',
				element: <AppForm />,
			},
		],
	},
];
