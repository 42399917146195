import eventsBus from '@shared/lib/eventsBus';
export type ModalType = {
	title: string;
	component: React.ReactNode;
};
const eventName = 'ModalOpen';
const subscribe = (callback: Function) => {
	return eventsBus.subscribe(eventName, callback);
};

const Open = (data: ModalType) => {
	eventsBus.broadcast(eventName, data);
};

const events = {
	subscribe,
	Open,
};
export default events;
