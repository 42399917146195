import React, { useEffect } from 'react';
import { EditCvForm } from '@features/edit-cv';
import { AppModel } from '@processes/app';

const EditCv = () => {
	const SetGuest = AppModel.useAppStore((state) => state.SetGuest);
	useEffect(() => {
		SetGuest(false);
	}, []); //eslint-disable-line
	return <EditCvForm />;
};

export default EditCv;
