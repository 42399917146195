import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { NotificationEvent } from '@processes/notification';
import { TokenService } from '@shared/api/token';
import ZodParser from '@shared/services/zod-parser';
import { Error } from '@shared/types/core';
import { ZodSchema } from 'zod';

const $api = axios.create({
	withCredentials: false,
	baseURL: process.env.REACT_APP_API_URL,
});

$api.defaults.headers.get['Accept'] = 'application/json';
$api.defaults.headers.post['Accept'] = 'application/json';

$api.interceptors.request.use((config) => {
	const token = localStorage.getItem('accessToken') || '';
	if (config.headers) config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
	if (config.headers) config.headers['X-Auth-Token'] = `Bearer ${token}`;

	return config;
});

$api.interceptors.response.use(
	(config) => {
		return config;
	},
	(error: AxiosError | any) => {
		const errorMessage = error?.response?.data?.message;
		NotificationEvent.open({
			type: 'error',
			info: {
				message: errorMessage ? '' : 'Something went wrong, try again',
				description: errorMessage,
			},
		});
		const originalRequest = error.config;

		const status = error.response.status;
		if ((status === 418 || status === 318) && error.config && !error.config._isRetry) {
			originalRequest._isRetry = true;
			TokenService.refresh()
				.then(({ data }) => {
					TokenService.updateTokens(data.accessToken, data.refreshToken, data.expiresAt);
				})
				.catch((_error: AxiosError) => {
					TokenService.clearTokens();
				});
		}
		return Promise.reject(error);
	},
);

export default $api;

export async function axiosRequest<T>({
	url,
	method,
	data,
	params,
	headers,
	responseType,
	showError = true,
	paramsSerializer,
	parser,
}: {
	url: string;
	method: AxiosRequestConfig['method'];
	data?: AxiosRequestConfig['data'];
	params?: AxiosRequestConfig['params'];
	headers?: AxiosRequestConfig['headers'];
	responseType?: AxiosRequestConfig['responseType'];
	paramsSerializer?: AxiosRequestConfig['paramsSerializer'];
	showError?: boolean;
	parser?: { list?: true; schema: ZodSchema };
}): Promise<{ data: T }> {
	try {
		const result = await $api({
			url,
			method,
			data,
			params,
			headers,
			paramsSerializer,
			responseType,
		});
		if (parser) {
			if ('list' in parser) {
				ZodParser.parse(parser.schema, result.data?.content, url);
			} else {
				ZodParser.parse(parser.schema, result.data, url);
			}
		}
		return { data: result.data };
	} catch (axiosError) {
		const err = axiosError as AxiosError;
		const error = {
			status: err.response?.status,
			data: err.response?.data || err.message,
		} as Error;

		//todo return when delete in interseptor
		// if (showError) {
		// 	NotificationEvent.open({ type: 'error', info: error.data as NotificationType['info'] });
		// }
		throw error;
	}
}

export const axiosBaseQuery =
	(): BaseQueryFn<
		{
			url: string;
			method: AxiosRequestConfig['method'];
			data?: AxiosRequestConfig['data'];
			params?: AxiosRequestConfig['params'];
		},
		unknown,
		unknown
	> =>
	async ({ url, method, data, params }) => {
		try {
			const result = await $api({ url: url, method, data, params });
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};
