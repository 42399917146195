import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, axiosRequest } from '@shared/api';
import { AuthModel } from '.';

export const GuestAccesses = async (password: string) => {
	await axiosRequest({
		method: 'POST',
		url: '/auth/guest',
		data: { password: btoa(password) },
	});
};

export const Api = createApi({
	reducerPath: 'authApi',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['authentication', 'email', 'app'],
	endpoints: (builder) => ({
		login: builder.mutation<AuthModel.TLoginResponse, AuthModel.TCredentials>({
			query: (credentials) => ({
				url: 'auth/sign-in',
				method: 'POST',
				data: credentials,
			}),
		}),
		sendOTPtoEmail: builder.mutation<
			AuthModel.TResponseEmailSend,
			Pick<AuthModel.TCredentials2Fe, 'username'>
		>({
			query: ({ username }) => ({
				url: 'auth/send-otp-email',
				method: 'POST',
				data: { username },
			}),
			invalidatesTags: ['email'],
		}),
		authenticationEmail: builder.mutation<AuthModel.TResponseToken, AuthModel.TCredentials2Fe>({
			query: (credentials) => ({
				url: 'auth/sign-in-email',
				method: 'POST',
				data: credentials,
			}),
		}),
		authenticationApp: builder.mutation<AuthModel.TResponseToken, AuthModel.TCredentials2Fe>({
			query: (credentials) => ({
				url: 'auth/sign-in-app',
				method: 'POST',
				data: credentials,
			}),
			invalidatesTags: ['app'],
		}),
	}),
});
export const {
	useLoginMutation: useLogin,
	useSendOTPtoEmailMutation: useSendOTPtoEmail,
	useAuthenticationEmailMutation: useAuthenticationEmail,
	useAuthenticationAppMutation: useAuthenticationApp,
} = Api;
