import React from 'react';
import { Provider } from 'react-redux';
import { setupStore } from '../store';

const store = setupStore();
const WithStore = (component: () => React.ReactNode) => () => {
	return <Provider store={store}>{component()}</Provider>;
};

export default WithStore;
