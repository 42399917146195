import React from 'react';
import styles from './styles.module.scss';
import { Avatar, Dropdown, Menu, Spin } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useAppSelector } from '@shared/model';
import { UserModel } from '@entities/user';
import { getFirstLetters } from '@entities/user/lib';
import { useLocale } from '@processes/app/lib';
import { AuthLib } from '@features/authentification';

const text = {
	en: {
		logout: 'Logout',
	},
	ru: {
		logout: 'Выйти',
	},
};

const UserInfo = () => {
	const { logout } = AuthLib.useLogout();
	const { isLoading } = useAppSelector(UserModel.selectors.status);
	const user = useAppSelector(UserModel.selectors.user);
	const { locale } = useLocale();

	function onLogoutClick() {
		logout();
	}
	const items = [
		{
			label: text[locale].logout,
			key: '0',
		},
	];

	const menuProps = {
		items,
		onClick: onLogoutClick,
	};

	return (
		<Spin spinning={isLoading} wrapperClassName={styles.spin}>
			<Dropdown className={styles.user} menu={menuProps}>
				<div className={styles.user__content}>
					<div className={styles.user__info}>
						<strong>{user?.username}</strong>
						<span>{user?.position}</span>
					</div>
					<CaretDownOutlined />
				</div>
			</Dropdown>
			<Avatar className={styles.avatar} src={user?.avatarUrl} size="large">
				{user && getFirstLetters(user.username)}
			</Avatar>
		</Spin>
	);
};
export default UserInfo;
