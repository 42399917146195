import React from 'react';
import styles from './style.module.scss';
import { Tabs } from 'antd';
import About from '../about';
import Education from '../education';
import Language from '../language';
import Certification from '../certification';
import Skills from '../skills';
import Projects from '../projects';
import { useAppStore } from '@processes/app/store';

const translation = {
	about: {
		ru: 'О себе',
		en: 'About',
	},
	education: {
		ru: 'Образование',
		en: 'Education',
	},
	languages: {
		ru: 'Языки',
		en: 'Languages',
	},
	certification: {
		ru: 'Сертификаты',
		en: 'Certification',
	},
	skills: {
		ru: 'Навыки',
		en: 'Skills',
	},
	projects: {
		ru: 'Проекты',
		en: 'Projects',
	},
};

const FormTabs = () => {
	const locale = useAppStore((state) => state.locale);

	const tabs = [
		{ label: translation.about[locale], key: 'About', children: <About /> },
		{ label: translation.education[locale], key: 'Education', children: <Education /> },
		{ label: translation.languages[locale], key: 'Languages', children: <Language /> },
		{ label: translation.certification[locale], key: 'Certification', children: <Certification /> },
		{ label: translation.skills[locale], key: 'Skills', children: <Skills /> },
		{ label: translation.projects[locale], key: 'Projects', children: <Projects /> },
	];

	return (
		<div className={styles.tabs}>
			<Tabs type="card" items={tabs} />
		</div>
	);
};
export default FormTabs;
