import React, { FC } from 'react';
import styles from './style.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField } from '@shared/ui/form-items';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import { CvModel } from '@entities/cv';
import { useAppStore } from '@processes/app/store';
const { Text } = Typography;

export const text = {
	en: 'This field is required',
	ru: 'Поле обязательно к заполнению',
};

const translation = {
	list_educations: {
		ru: 'Список полученных образований',
		en: 'List of added education',
	},
	empty_list: {
		ru: 'Не добавлены полученные образования',
		en: 'No education added',
	},
	university: {
		ru: 'Университет',
		en: 'University',
	},
	placeholder_university: {
		ru: 'Введите название учебного заведения',
		en: 'Enter university name',
	},
	faculty: {
		ru: 'Факультет',
		en: 'Faculty',
	},
	placeholder_faculty: {
		ru: 'Введите название факультета',
		en: 'Enter faculty name',
	},
	speciality: {
		ru: 'Специальность',
		en: 'Speciality',
	},
	placeholder_speciality: {
		ru: 'Введите название специальности',
		en: 'Enter speciality name',
	},
	hint: {
		ru: 'Все поля должны быть заполнены',
		en: 'All fields must be completed',
	},
	add: {
		ru: 'Добавить',
		en: 'Add',
	},
	save: {
		ru: 'Изменить',
		en: 'Ok',
	},
	cancel: {
		ru: 'Закрыть',
		en: 'Cancel',
	},
};

type Form = {
	modal?: never;
	closeModal?: never;
	onSetValue: Function;
	defaultValue?: never;
};
type FormInModal = {
	modal: boolean;
	closeModal: () => void;
	onSetValue: (value: CvModel.TEducation) => void;
	defaultValue: CvModel.TEducation;
};
type Props = Form | FormInModal;

const ValidateInputs = (required: string, locale: string) => {
	return {
		required: required,
		validate: (value: any) => {
			if (value.trim() === '')
				return locale === 'en'
					? 'You cannot insert only Spaces'
					: 'Нельзя вставлять только пробелы';
			else if (value.charAt(0) === ' ') {
				return locale === 'en'
					? "You can't start typing with a Space"
					: 'Нельзя начинать слово с пробела';
			}
		},
	};
};

const FormComponent: FC<Props> = ({ modal, closeModal, onSetValue, defaultValue }) => {
	const locale = useAppStore((state) => state.locale);
	const methods = useForm<CvModel.TEducation>({
		mode: 'onChange',
		defaultValues: defaultValue || { university: '', faculty: '', speciality: '' },
	});
	const {
		formState: { isValid, errors },
		control,
		getValues,
		reset,
		handleSubmit,
	} = methods;
	const onButtonClick = () => {
		if (isValid) {
			onSetValue(getValues());
			reset();
		}
	};

	const isDisabled = !!errors.faculty || !!errors.university || !!errors.speciality;
	return (
		<div className={classNames(styles.form, { modal: modal })}>
			<FormProvider {...methods}>
				<InputField
					main={{
						label: translation.university[locale],
						placeholder: translation.placeholder_university[locale],
						allowClear: true,
					}}
					name={'university'}
					control={control}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<InputField
					main={{
						label: translation.faculty[locale],
						placeholder: translation.placeholder_faculty[locale],
						allowClear: true,
					}}
					control={control}
					name={'faculty'}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<InputField
					main={{
						label: translation.speciality[locale],
						placeholder: translation.placeholder_speciality[locale],
						allowClear: true,
					}}
					control={control}
					name={'speciality'}
					rules={ValidateInputs(text[locale], locale)}
				/>
				<div className={styles.footer}>
					<Text>{translation.hint[locale]}</Text>
					<Button
						title={isDisabled ? translation.hint[locale] : ''}
						disabled={isDisabled}
						type={'primary'}
						onClick={handleSubmit(onButtonClick)}
						className={classNames(styles.submit)}
					>
						{modal ? translation.save[locale] : translation.add[locale]}
					</Button>
					{modal && (
						<Button onClick={closeModal} type="default">
							{translation.cancel[locale]}
						</Button>
					)}
				</div>
			</FormProvider>
		</div>
	);
};

export default FormComponent;
