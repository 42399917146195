import React, { useState } from 'react';
import styles from './style.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { DragIndicatorSvg, InfoOutlineSvg } from '@app/assets/svg';
import EditItem from '@shared/ui/edit-item';
import { Collapse, Tooltip, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';
import { ModalCloseEvent, ModalOpenEvent } from '@processes/modal';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { InnerHTMLWithBr } from '@shared/lib/inner-html';
import FormComponent from '../form';
import dayjs from 'dayjs';
import { useLocale } from '@processes/app/lib';
import { CheckboxField } from '@shared/ui/form-items';
import { EditCvModel } from '@features/edit-cv';
const { Text, Title } = Typography;
const { Panel } = Collapse;

const translation = {
	title: {
		ru: 'Редактирование проекта',
		en: 'Project editing',
	},
	list_projects: {
		ru: 'Список добавленных проектов',
		en: 'List of added projects',
	},
	empty_list: {
		ru: 'Нет добавленных проектов',
		en: 'No projects added',
	},
	description: {
		ru: 'Описание',
		en: 'Description',
	},
	team: {
		ru: 'Команда',
		en: 'Team',
	},
	position: {
		ru: 'Должность в компании',
		en: 'Job position',
	},
	role: {
		ru: 'Роль на проекте',
		en: 'Project roles',
	},
	tasks: {
		ru: 'Задачи',
		en: 'Tasks',
	},
	technologies: {
		ru: 'Технологии',
		en: 'Technologies',
	},
	hint: {
		ru: 'Генерация профиля со скрытыми названиями проектов',
		en: 'Generating a profile with hidden project names',
	},
};

const List = () => {
	const [isDragging, setIsDragging] = useState<boolean | null>(null);
	const { setValue, watch, control } = useFormContext<EditCvModel.CvFormDto>();
	const { locale } = useLocale();

	const pastProjectsList = watch('pastProjects');

	const onDragHandler = (newState: EditCvModel.CvFormDto['pastProjects']) => {
		setValue('pastProjects', newState, { shouldDirty: typeof isDragging === 'boolean' });
	};
	const onDelete = (indexItem: string) => () => {
		const filtered = pastProjectsList.filter(({ id }) => id !== indexItem);
		setValue('pastProjects', filtered, { shouldDirty: true });
	};
	const onEdit =
		(index: number) =>
		({ periodStart, periodEnd, dateOnGoing, ...rest }: EditCvModel.TPastProjectExpForm) => {
			setValue(
				`pastProjects.${index}`,
				{
					id: uuidv4(),
					item: {
						...rest,
						periodStart: periodStart.format('MM.YYYY'),
						periodEnd: !dateOnGoing ? (periodEnd ? periodEnd.format('MM.YYYY') : '') : '',
						dateOnGoing: dateOnGoing,
					},
				},
				{ shouldDirty: true },
			);
			ModalCloseEvent.Close();
		};
	const showModal = (id: string) => () => {
		const index = pastProjectsList.findIndex((el) => el.id === id);
		const project = pastProjectsList[index].item;
		ModalOpenEvent.Open({
			title: translation.title[locale],
			component: (
				<FormComponent
					modal
					closeModal={ModalCloseEvent.Close}
					onSetValue={onEdit(index)}
					defaultValue={{
						name: project.name,
						periodStart: project.periodStart
							? dayjs()
									.set('month', project.periodStart.split('.')[0] - 1)
									.set('year', project.periodStart.split('.')[1])
							: '',
						periodEnd: project.periodEnd
							? dayjs()
									.set('month', project.periodEnd.split('.')[0] - 1)
									.set('year', project.periodEnd.split('.')[1])
							: '',
						dateOnGoing: project.dateOnGoing,
						description: project.description,
						tasks: project.tasks,
						team: project.team,
						technologies: project.technologies,
						jobPosition: project.jobPosition,
						projectRoles: project.projectRoles,
					}}
				/>
			),
		});
	};

	const genExtra = (id: string) => (
		<div
			className={styles.action}
			onClick={(event: any) => {
				event.stopPropagation();
			}}
		>
			<EditItem onDelete={onDelete(id)} onEdit={showModal(id)} />
		</div>
	);

	return (
		<div className={styles.list}>
			<div className={styles.title}>
				<Title>{translation.list_projects[locale]}</Title>
				{/*<div className={styles.nda}>*/}
				{/*	<CheckboxField main={{ label: 'NDA' }} control={control} name={'nda'} />*/}
				{/*	<Tooltip title={translation.hint[locale]}>*/}
				{/*		<InfoOutlineSvg style={{ color: 'rgba(0,0,0,.45)' }} />*/}
				{/*	</Tooltip>*/}
				{/*</div>*/}
			</div>
			<div className={styles.elements}>
				{pastProjectsList.length === 0 && (
					<div className={styles.list_empty}>
						<Text>{translation.empty_list[locale]}</Text>
					</div>
				)}
				<ReactSortable
					onStart={() => setIsDragging(true)}
					onUnchoose={() => setIsDragging(false)}
					list={pastProjectsList}
					setList={onDragHandler}
					animation={250}
					handle={'.handle'}
				>
					{pastProjectsList.map(({ id, item }) => (
						<Collapse
							expandIconPosition={'end'}
							className={classNames(styles.category, { isDragging: isDragging })}
							key={id}
							items={[
								{
									className: classNames(styles.panel),
									key: item.name + id,
									label: (
										<div className={styles.panel__content}>
											<DragIndicatorSvg className={classNames('handle', styles.dragIcon)} />
											<div className={styles.header}>
												<span className={styles.header__name}>{item.name}</span>
												<span className={styles.header__period}>
													{item.periodStart} -{' '}
													{item.dateOnGoing
														? locale === 'en'
															? 'ongoing'
															: 'по настоящее время'
														: item.periodEnd}
												</span>
											</div>
											{genExtra(id)}
										</div>
									),
									children: (
										<div className={styles.project}>
											<div className={styles.description}>
												<span>{translation.description[locale]}</span>{' '}
												<p dangerouslySetInnerHTML={InnerHTMLWithBr(item.description)} />
											</div>
											<div className={styles.team}>
												<span>{translation.team[locale]}</span> {item.team}
											</div>
											<div className={styles.position}>
												<span>{translation.position[locale]}</span> {item.jobPosition}
											</div>
											<div className={styles.roles}>
												<span>{translation.role[locale]}</span> {item.projectRoles}
											</div>
											<div className={styles.tasks}>
												<span>{translation.tasks[locale]}</span>{' '}
												<p dangerouslySetInnerHTML={InnerHTMLWithBr(item.tasks)} />
											</div>
											<div className={styles.technologies}>
												<span>{translation.technologies[locale]}</span>{' '}
												<p dangerouslySetInnerHTML={InnerHTMLWithBr(item.technologies)} />
											</div>
										</div>
									),
								},
							]}
						></Collapse>
					))}
				</ReactSortable>
			</div>
		</div>
	);
};

export default List;
