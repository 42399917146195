import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { TokenService } from '@shared/api/token';
import { useActionCreators } from '@shared/model/hooks';
import { UserModel } from '@entities/user';
import { AuthModel } from '..';

export function useAuthentication() {
	const navigate = useNavigate();
	const actionsAuth = useActionCreators(AuthModel.actions);
	const actionsUser = useActionCreators(UserModel.actions);

	const loginInApplication = useCallback((data: AuthModel.TResponseToken) => {
		TokenService.updateTokens(data.accessToken, data.refreshToken, data.expiresAt);
		actionsAuth.changeLoginStatus({ isLogin: true });
		actionsUser.getUserInfo();
		navigate('/', { replace: true });
	}, []);//eslint-disable-line

	return { loginInApplication };
}
