type T = {
	isProfile: boolean;
	userName: string | null;
};
export default function useGetSearchParams(): T {
	const url = new URL(window.location.href);
	const params = url.pathname.split('/');
	return {
		isProfile: params[1] === 'cv',
		userName: url.searchParams.get('username'),
	};
}
