export const Positions = [
	'Assistant Software Engineer',
	'Junior Software Engineer',
	'Software Engineer',
	'Senior Software Engineer',
	'Lead Software Engineer',
	'Chief Software Engineer',
	'Solution Architect',

	'Assistant Software Testing Engineer',
	'Junior Software Testing Engineer',
	'Software Testing Engineer',
	'Senior Software Testing Engineer',
	'Lead Software Testing Engineer',
	'Chief Software Testing Engineer',
	'Assistant Software Testing Automation Engineer',
	'Junior Software Testing Automation Engineer',
	'Software Testing Automation Engineer',
	'Senior Software Testing Automation Engineer',
	'Lead Software Testing Automation Engineer',
	'Chief Software Testing Automation Engineer',

	'Assistant Business Analyst',
	'Business Analyst',
	'Junior Business Analyst',
	'Lead Business Analyst',
	'Senior Business Analyst',
	'Chief Business Analyst',

	'Junior System Analyst',
	'System Analyst',
	'Senior System Analyst',
	'Lead System Analyst',
	'Chief System Analyst',

	'Junior Designer',
	'Designer',
	'Senior Designer',
	'Lead Designer',

	'Junior UI/UX Engineer',
	'UI/UX Engineer',
	'Senior UI/UX Engineer',
	'Lead UI/UX Engineer',

	'Junior Support Engineer',
	'Support Engineer',
	'Senior Support Engineer',
	'Lead Support Engineer',
	'Chief Support Engineer',

	'Junior Development & Operations Engineer',
	'Development & Operations Engineer',
	'Senior Development & Operations Engineer',

	'Junior System Administrator',
	'System Administrator',
	'Senior System Administrator',

	'Salesforce Administrator',

	'Junior Project Manager',
	'Project Manager',
	'Senior Project Manager',
];
export const PositionsRu = [
	'Помощник инженера-программиста',
	'Младший инженер-программист',
	'Инженер-программист',
	'Старший инженер-программист',
	'Ведущий инженер-программист',
	'Главный инженер-программист',
	'Архитектор решений',
	'Помощник инженера по тестированию программного обеспечения',
	'Младший инженер по тестированию программного обеспечения',
	'Инженер по тестированию программного обеспечения',
	'Старший инженер по тестированию программного обеспечения',
	'Ведущий инженер по тестированию программного обеспечения',
	'Главный инженер по тестированию программного обеспечения',
	'Помощник инженера по автоматизации тестирования программного обеспечения',
	'Младший инженер по автоматизации тестирования программного обеспечения',
	'Инженер по автоматизации тестирования программного обеспечения',
	'Старший инженер по автоматизации тестирования программного обеспечения',
	'Ведущий инженер по автоматизации тестирования программного обеспечения',
	'Главный инженер по автоматизации тестирования программного обеспечения',

	'Помощник бизнес-аналитика',
	'Бизнес-аналитик',
	'Младший бизнес-аналитик',
	'Ведущий бизнес-аналитик',
	'Старший бизнес-аналитик',
	'Главный бизнес-аналитик',

	'Младший системный аналитик',
	'Системный аналитик',
	'Старший системный аналитик',
	'Ведущий системный аналитик',
	'Главный системный аналитик',

	'Младший дизайнер',
	'Дизайнер',
	'Старший дизайнер',
	'Ведущий дизайнер',

	'Младший инженер по пользовательскому интерфейсу/UX',
	'Инженер по пользовательскому интерфейсу/UX',
	'Старший инженер по пользовательскому интерфейсу/UX',
	'Ведущий инженер по пользовательскому интерфейсу/UX',

	'Младший инженер службы поддержки',
	'Инженер службы поддержки',
	'Старший инженер службы поддержки',
	'Ведущий инженер службы поддержки',
	'Главный инженер службы поддержки',

	'Младший инженер по разработке и эксплуатации',
	'Инженер по разработке и эксплуатации',
	'Старший инженер по разработке и эксплуатации',

	'Младший системный администратор',
	'Системный администратор',
	'Старший системный администратор',

	'Администратор Salesforce',

	'Младший проектный менеджер',
	'Проектный менеджер',
	'Старший проектный менеджер',
];
export const LanguageLevels = [
	'A1 (beginner)',
	'A2 (pre-intermediate)',
	'B1 (intermediate)',
	'B2 (upper-intermediate)',
	'C1 (advanced)',
	'C2 (proficiency)',
	'Native',
];
export const LanguageLevelsRu = [
	'A1 (начальный)',
	'A2 (ниже среднего)',
	'B1 (средний)',
	'B2 (выше среднего)',
	'C1 (продвинутый)',
	'C2 (профессиональный)',
	'Носитель языка',
];
export const Languages = ['English', 'German', 'French', 'Spanish'];
export const LanguagesRu = ['Английский', 'Немецкий', 'Французский', 'Испанский'];
export const SkillLevels = ['Novice', 'Intermediate', 'Advanced', 'Expert'];
export const SkillLevelsRu = ['Начинающий', 'Средний', 'Продвинутый', 'Эксперт'];
export const SkillCategories = [
	'Programming Languages',
	'Programming Technologies',
	'Framework',
	'Libraries',
	'DataBases',
	'Structures of data organization and transformation',
	'Version Control System',
	'Building tools',
	'Methodologies',
	'Tools/Utils',
	'Operating systems',
	'Defect Tracking Systems',
	'ORM',
	'Testing',
	'Test Management Systems',
	'User Experience',
	'Android Platform',
	'Clouds Technologies',
	'Salesforce Clouds',
	'Salesforce Platform Configuration',
	'Salesforce Platform Development',
	'Other',
];
export const SkillCategoriesRu = [
	'Языки программирования',
	'Технологии',
	'Фреймворки',
	'Библиотеки',
	'Базы данных',
	'Структуры организации и преобразования данных',
	'Системы контроля версий',
	'Инструменты сборки',
	'Методологии',
	'Инструменты/Утилиты',
	'Операционные системы',
	'Системы отслеживания дефектов',
	'ORM',
	'Тестирование',
	'Системы управления тестированием',
	'Пользовательский опыт',
	'Платформа Андроид',
	'Облачные технологии',
	'Облака SalesForce',
	'Конфигурация платформы Salesforce',
	'Разработка платформы Salesforce',
	'Другое',
];
export const Cities = [
	'Minsk',
	'Grodno',
	'Gomel',
	'Brest',
	'Orel',
	'Tula',
	'Kaluga',
	'Nizhny Novgorod',
	'Moscow',
	'Vladimir',
	'Zagreb',
	'Warsaw',
	'Tbilisi',
	'Tashkent',
];
export const CitiesRu = [
	'Минск',
	'Гродно',
	'Гомель',
	'Брест',
	'Орел',
	'Тула',
	'Калуга',
	'Нижний Новгород',
	'Москва',
	'Владимир',
	'Загреб',
	'Варшава',
	'Тбилиси',
	'Ташкент',
];
export const Citizenship = ['Belarus', 'Russia', 'Georgia', 'Poland', 'Croatia', 'Uzbekistan'];
export const CitizenshipRu = ['Беларусь', 'Россия', 'Грузия', 'Польша', 'Хорватия', 'Узбекистан'];
