import { FC } from 'react';
import './style.scss';
import { FacebookSvg, InstagramSvg, LinkedinSvg, TwitterSvg, CopyrightSvg } from '@app/assets/svg';
import { useAppStore } from '@processes/app/store';

const socialMedia = [
	{
		name: 'Linkedin',
		link: 'https://www.linkedin.com/company/senla-software-engineering-laboratory',
		icon: <LinkedinSvg />,
	},
	{
		name: 'Instagram',
		link: 'https://www.instagram.com/senlainc/',
		icon: <InstagramSvg />,
	},
	{
		name: 'Facebook',
		link: 'https://www.facebook.com/pages/category/Software-Company/SENLA-102398111339100/',
		icon: <FacebookSvg />,
	},
	{
		name: 'Twitter',
		link: 'https://twitter.com/senlainc?s=21',
		icon: <TwitterSvg />,
	},
];

const text = {
	company_page: {
		ru: 'Официальный сайт компании',
		en: 'Official company page',
	},
	feedback: {
		ru: 'Обратная связь',
		en: 'Feedback',
	},
	support: {
		ru: 'Техническая поддержка',
		en: 'Technical support',
	},
	sections: {
		ru: 'Раздел',
		en: 'Sections',
	},
};

const currentYear = new Date().getFullYear();
const Footer: FC = () => {
	const locale = useAppStore((state) => state.locale);
	return (
		<footer className="footer">
			<div className="footer__top-container">
				<div className="footer__category">
					<h4 className="footer__category-heading">{text.company_page[locale]}</h4>
					<a
						className="footer__category-external-link"
						href="https://senlainc.com/"
						target="_blank"
						rel="noreferrer"
					>
						www.senlainc.com
					</a>
				</div>

				<div className="footer__category">
					<h4 className="footer__category-heading">{text.feedback[locale]}</h4>
					<a href="mailto:aliaksandr_yaravy@senla.eu" className="footer__category-mail">
						aliaksandr_yaravy@senla.eu
					</a>
				</div>

				<div className="footer__category">
					<h4 className="footer__category-heading">{text.support[locale]}</h4>
					<a href="mailto:admin@senla.eu" className="footer__category-mail">
						admin@senla.eu
					</a>
				</div>

				<div className="footer__category">
					<h4 className="footer__category-heading">{text.sections[locale]}</h4>
					<span className="footer__category-mail">
						{locale === 'en' ? 'Profile Generator' : 'Генератор профиля'}
					</span>
				</div>
			</div>

			<div className="footer__bottom-container">
				<div className="footer__copyright">
					<CopyrightSvg className="footer__copyright-logo" />
					<span className="footer__copyright-text">
						{currentYear} SENLA - Software Engineering Laboratory
					</span>
				</div>

				<ul className="footer__social-media-list">
					{socialMedia.map(({ icon, link }) => (
						<li key={link} className="footer__social-media-item">
							<a className="footer__social-media-link" href={link} target="_blank" rel="noreferrer">
								{icon}
							</a>
						</li>
					))}
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
