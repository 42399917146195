import React, { useState } from 'react';
import styles from './style.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { DragIndicatorSvg } from '@app/assets/svg';
import EditItem from '@shared/ui/edit-item';
import { Typography } from 'antd';
import { useFormContext } from 'react-hook-form';
import { ModalCloseEvent, ModalOpenEvent } from '@processes/modal';
import FormComponent from '../form';
import { v4 as uuidv4 } from 'uuid';
import { CvModel } from '@entities/cv';
import { useLocale } from '@processes/app/lib';
import { EditCvModel } from '@features/edit-cv';
const { Text, Title } = Typography;

const translation = {
	title: {
		ru: 'Редактирование образования',
		en: 'Education editing',
	},
	list_educations: {
		ru: 'Список полученных образований',
		en: 'List of added education',
	},
	empty_list: {
		ru: 'Не добавлены полученные образования',
		en: 'No education added',
	},
	faculty: {
		ru: 'Факультет',
		en: 'Faculty',
	},
	speciality: {
		ru: 'Специальность',
		en: 'Speciality',
	},
};

const List = () => {
	const { setValue, watch } = useFormContext<EditCvModel.CvFormDto>();
	const [isDragging, setIsDragging] = useState<null | boolean>(null);
	const { locale } = useLocale();

	const educationList = watch('education');

	const onDragHandler = (newState: EditCvModel.CvFormDto['education']) => {
		setValue('education', newState, { shouldDirty: typeof isDragging === 'boolean' });
	};
	const onDelete = (indexItem: string) => () => {
		const filtered = educationList.filter(({ id }) => id !== indexItem);
		setValue('education', filtered, { shouldDirty: true });
	};
	const onEditCertification = (index: number) => (value: CvModel.TEducation) => {
		setValue(`education.${index}`, { id: uuidv4(), item: value }, { shouldDirty: true });
		ModalCloseEvent.Close();
	};
	const showModal = (id: string) => () => {
		const index = educationList.findIndex((el) => el.id === id);
		ModalOpenEvent.Open({
			title: translation.title[locale],
			component: (
				<FormComponent
					modal
					closeModal={ModalCloseEvent.Close}
					onSetValue={onEditCertification(index)}
					defaultValue={educationList[index].item}
				/>
			),
		});
	};

	return (
		<div className={styles.list}>
			<div className={styles.title}>
				<Title>{translation.list_educations[locale]}</Title>
			</div>
			<div className={styles.elements}>
				{educationList.length === 0 && (
					<div className={styles.list_empty}>
						<Text>{translation.empty_list[locale]}</Text>
					</div>
				)}
				<ReactSortable
					onStart={() => setIsDragging(true)}
					onUnchoose={() => setIsDragging(false)}
					list={educationList}
					setList={onDragHandler}
					animation={250}
					handle={'.handle'}
				>
					{educationList.map(({ id, item }) => (
						<div className={styles.element} key={id}>
							<DragIndicatorSvg className="handle" />
							<div className={styles.element__content}>
								<div className={styles.university}>{item.university}</div>
								<div className={styles.faculty}>
									<small>{translation.faculty[locale]}:</small> {item.faculty}
								</div>
								<div className={styles.speciality}>
									<small>{translation.speciality[locale]}:</small> {item.speciality}
								</div>
							</div>
							<div className={styles.more}>
								<EditItem onEdit={showModal(id)} onDelete={onDelete(id)} />
							</div>
						</div>
					))}
				</ReactSortable>
			</div>
		</div>
	);
};

export default List;
