import React, { useRef, useState } from 'react';
import styles from './style.module.scss';
import GroupLabel from '@shared/ui/group-label';
import { Collapse } from 'antd';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import FormComponent from './form';
import List from './list';
import { CvModel } from '@entities/cv';
import InnerHTML from '@shared/lib/inner-html';
import InstructionsWrapper from '../instructions-wrapper';
import { fillingInstructionsEn, fillingInstructionsRu } from './text';
import { EditCvModel } from '@features/edit-cv';
import { useAppStore } from '@processes/app/store';

const translation = {
	add_education: {
		ru: 'Добавить учебное заведение',
		en: 'Add education',
	},
};

const Education = () => {
	const locale = useAppStore((state) => state.locale);
	const { setValue, getValues } = useFormContext<EditCvModel.CvFormDto>();
	const fillingInstructions = locale === 'en' ? fillingInstructionsEn : fillingInstructionsRu;
	const instructionRef = useRef<HTMLInputElement>(null);
	const [activeKeys, setActiveKeys] = useState<string[]>([]);
	const activateCollapsePanel = (activeKey: string) => {
		if (!activeKeys.includes(activeKey)) {
			setActiveKeys((prevState) => [...prevState, activeKey]);
		}
		instructionRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const onAdd = (value: CvModel.TEducation) => {
		setValue(
			`education.${getValues().education.length}`,
			{
				id: uuidv4(),
				item: value,
			},
			{ shouldDirty: true },
		);
	};

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.add_section}>
					<div className={styles.add_title}>
						<GroupLabel
							handleClick={() => activateCollapsePanel(fillingInstructions[0].name)}
							text={translation.add_education[locale]}
						/>
					</div>
					<FormComponent onSetValue={onAdd} />
				</div>
				<List />
			</div>
			<div ref={instructionRef}>
				<InstructionsWrapper>
					<Collapse
						className="filling-instructions"
						activeKey={activeKeys}
						onChange={(key) => setActiveKeys(key as string[])}
						ghost
						items={[
							{
								key: fillingInstructions[0].name,
								label: fillingInstructions[0].name,
								children: <p dangerouslySetInnerHTML={InnerHTML(fillingInstructions[0].text)} />,
							},
						]}
					></Collapse>
				</InstructionsWrapper>
			</div>
		</div>
	);
};

export default Education;
