import React, { useEffect } from 'react';
import { EditCvForm } from '@features/edit-cv';
import { AppModel, SelectLocale } from '@processes/app';
import styles from './style.module.scss';
import { CompanyLogoShortSvg, CompanyLogoSvg } from '@app/assets/svg';
import { useNavigate } from 'react-router-dom';
import { Space, Tooltip } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

const Guest = () => {
	const navigate = useNavigate();
	const SetGuest = AppModel.useAppStore((state) => state.SetGuest);
	const SetGuestAccesses = AppModel.useAppStore((state) => state.SetGuestAccesses);
	const { guestHasAccesses, guest } = AppModel.useAppStore((state) => ({
		guestHasAccesses: state.guestHasAccesses,
		guest: state.guest,
	}));

	const logout = () => {
		SetGuest(false);
		SetGuestAccesses(false);
		navigate('/');
	};

	useEffect(() => {
		if (!guest) {
			SetGuest(true);
		}
		if (!guestHasAccesses) {
			navigate('/');
		}
	}, [guest, guestHasAccesses]);//eslint-disable-line

	function onLogoClick() {
		navigate('/');
	}
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				{window.innerWidth > 1024 && (
					<CompanyLogoSvg onClick={onLogoClick} className={styles.logo} />
				)}
				{window.innerWidth <= 1024 && (
					<CompanyLogoShortSvg onClick={onLogoClick} className={styles.logo} />
				)}
				<Space direction={'horizontal'}>
					<SelectLocale />
					<Tooltip title={'Logout'}>
						<LoginOutlined className={styles.logout} onClick={logout} />
					</Tooltip>
				</Space>
			</div>
			<div className={styles.form}>
				<EditCvForm />
			</div>
		</div>
	);
};

export default Guest;
