import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { GuestAccesses } from '@features/authentification/api';
import { AppModel } from '@processes/app';
import { Button, Input, Typography } from 'antd';
import styles from './styles.module.scss';
import { ModalCloseEvent } from '@processes/modal';
import { useNavigate } from 'react-router-dom';
const { Title } = Typography;

const ModalAccesses = () => {
	const navigate = useNavigate();
	const [value, setValue] = useState('');
	const SetGuestAccesses = AppModel.useAppStore((state) => state.SetGuestAccesses);
	const { mutate, isLoading } = useMutation({
		mutationFn: GuestAccesses,
		onSuccess: () => {
			SetGuestAccesses(true);
			ModalCloseEvent.Close();
			navigate('/guest');
		},
	});
	const onInputChange = (event: any) => {
		setValue(event.target.value);
	};

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<div className={styles.title__line}></div>
				<Title>Profile Generator</Title>
				<div className={styles.title__line}></div>
			</div>
			<div className="form-item">
				<Input onChange={onInputChange} value={value} placeholder={'Password'} />
			</div>

			<Button
				type={'primary'}
				loading={isLoading}
				onClick={() => {
					mutate(value);
				}}
			>
				Log in
			</Button>
		</div>
	);
};

export default ModalAccesses;
