import React, { useState } from 'react';
import styles from './style.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { DragIndicatorSvg } from '@app/assets/svg';
import EditItem from '@shared/ui/edit-item';
import { Typography } from 'antd';
import { useFormContext } from 'react-hook-form';
import { ModalCloseEvent, ModalOpenEvent } from '@processes/modal';
import FormComponent from '../form';
import { v4 as uuidv4 } from 'uuid';
import { CvModel } from '@entities/cv';
import { useLocale } from '@processes/app/lib';
import { EditCvModel } from '@features/edit-cv';
const { Text, Title } = Typography;

const translation = {
	title: {
		ru: 'Редактирование языка',
		en: 'Language editing',
	},
	list_languages: {
		ru: 'Список добавленных языков',
		en: 'List of added languages',
	},
	empty_list: {
		ru: 'Нет добавленных языков',
		en: 'No languages added',
	},
	language: {
		ru: 'Название языка',
		en: 'Language name',
	},
	level: {
		ru: 'Уровень владения',
		en: 'Level',
	},
};

const List = () => {
	const { setValue, watch } = useFormContext<EditCvModel.CvFormDto>();
	const [isDragging, setIsDragging] = useState<null | boolean>(null);
	const { locale } = useLocale();

	const languageList = watch('language');

	const onDragHandler = (newState: EditCvModel.CvFormDto['language']) => {
		setValue('language', newState, { shouldDirty: typeof isDragging === 'boolean' });
	};
	const onDelete = (indexItem: string) => () => {
		const filtered = languageList.filter(({ id }) => id !== indexItem);
		setValue('language', filtered, { shouldDirty: true });
	};
	const onEditCertification = (index: number) => (value: CvModel.TLanguages) => {
		setValue(`language.${index}`, { id: uuidv4(), item: value }, { shouldDirty: true });
		ModalCloseEvent.Close();
	};
	const showModal = (id: string) => () => {
		const index = languageList.findIndex((el) => el.id === id);
		ModalOpenEvent.Open({
			title: translation.title[locale],
			component: (
				<FormComponent
					modal
					closeModal={ModalCloseEvent.Close}
					onSetValue={onEditCertification(index)}
					defaultValue={languageList[index].item}
				/>
			),
		});
	};

	return (
		<div className={styles.list}>
			<div className={styles.title}>
				<Title>{translation.list_languages[locale]}</Title>
			</div>
			<div className={styles.elements}>
				{languageList.length === 0 && (
					<div className={styles.list_empty}>
						<Text>{translation.empty_list[locale]}</Text>
					</div>
				)}
				{languageList.length > 0 && (
					<div className={styles.header}>
						<span className={styles.header__language}>{translation.language[locale]}</span>
						<span className={styles.header__level}>{translation.level[locale]}</span>
					</div>
				)}

				<div className={styles.sortable}>
					<ReactSortable
						onStart={() => setIsDragging(true)}
						onUnchoose={() => setIsDragging(false)}
						list={languageList}
						setList={onDragHandler}
						animation={250}
						handle={'.handle'}
					>
						{languageList.map(({ id, item }) => (
							<div className={styles.element} key={id}>
								<DragIndicatorSvg className="handle" />
								<div className={styles.element__content}>
									<div className={styles.element__name}>{item.name}</div>
									<div className={styles.element__level}>{item.level}</div>
								</div>
								<div className={styles.more}>
									<EditItem onEdit={showModal(id)} onDelete={onDelete(id)} />
								</div>
							</div>
						))}
					</ReactSortable>
				</div>
			</div>
		</div>
	);
};

export default List;
