import React, { useEffect } from 'react';
import styles from './style.module.scss';
import { Button, Result, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { CompanyLogoSvg } from '@app/assets/svg';
import { TokenService } from '@shared/api/token';
import { useAppStore } from '@processes/app/store';
import { SelectLocale } from '@processes/app/ui';

const translation = {
	ru: {
		title: 'Полет пока невозможен',
		subtitle:
			'Твои данные отсутствуют. Попробуй зайти в другой раз. На добавление тебя в систему требуется время. Это задержка с нашей стороны. Но мы тебе будем тут очень рады!',
		hint: 'Если остались вопросы пиши на емейл ',
		button: 'на страницу входа',
	},
	en: {
		title: 'Flight is not possible yet',
		subtitle:
			'Your data is missing. Try to log in another time. It takes time to add you to the system. This is a delay on our part. But we will be very happy to see you here!',
		hint: 'If you have any questions, please contact ',
		button: 'To login page',
	},
};
const mail = 'admin@senla.eu';

const ErrorPage = () => {
	const navigate = useNavigate();
	const locale = useAppStore((state) => state.locale);

	useEffect(() => {
		TokenService.clearTokens();
	}, []);

	return (
		<div className={styles.error}>
			<div className={styles.select}>
				<SelectLocale />
			</div>
			<Result
				icon={<CompanyLogoSvg />}
				title={translation[locale].title}
				subTitle={
					<div className={styles.content}>
						<p className={styles.subtitle}>{translation[locale].subtitle}</p>
						<span className={styles.hint}>
							{translation[locale].hint}
							{<b>{mail}</b>}
						</span>
					</div>
				}
				extra={
					<Content>
						<Row justify={'center'}>
							<Button type="primary" className={styles.home} onClick={() => navigate('/')}>
								{translation[locale].button}
							</Button>
						</Row>
					</Content>
				}
			/>
		</div>
	);
};

export default ErrorPage;
