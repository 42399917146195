import React from 'react';
import { Button, Result, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';

const NotFoundPage = () => {
	const navigate = useNavigate();

	return (
		<Result
			status="404"
			title="404"
			subTitle="Sorry, the page you visited does not exist."
			extra={
				<Content>
					<Row justify={'center'}>
						<Button type="primary" onClick={() => navigate('/')}>
							Back Home
						</Button>
					</Row>
				</Content>
			}
		/>
	);
};

export default NotFoundPage;
