import eventsBus from '@shared/lib/eventsBus';

const eventName = 'ModalClose';
const subscribe = (callback: Function) => {
	return eventsBus.subscribe(eventName, callback);
};

const Close = () => {
	eventsBus.broadcast(eventName);
};

const events = {
	subscribe,
	Close,
};
export default events;
