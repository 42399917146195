import { FC, ReactNode } from 'react';
import './style.scss';
import { useLocale } from '@processes/app/lib';

interface IFormFooter {
	children?: ReactNode;
}

const text = {
	en: {
		instructions: 'Filling Instructions',
	},
	ru: {
		instructions: 'Инструкции по заполнению',
	},
};

const InstructionsWrapper: FC<IFormFooter> = ({ children }) => {
	const { locale } = useLocale();
	return (
		<div className="instructions-wrapper">
			<div className="instructions-wrapper__heading">{text[locale].instructions}</div>
			<div className="instructions-wrapper__content">{children}</div>
		</div>
	);
};

export default InstructionsWrapper;
