import { FC } from 'react';
import './style.scss';

interface IHamburgerBtn {
	isActive: boolean;
	handleClick: () => void;
}

const HamburgerBtn: FC<IHamburgerBtn> = ({ isActive, handleClick }) => {
	return (
		<button className={`hamburger-btn ${isActive ? 'active' : ''}`} onClick={handleClick}>
			<div className="hamburger-btn__stick-1"></div>
			<div className="hamburger-btn__stick-2"></div>
			<div className="hamburger-btn__stick-3"></div>
		</button>
	);
};

export default HamburgerBtn;
