import React, { useState } from 'react';
import styles from './style.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { DragIndicatorSvg } from '@app/assets/svg';
import EditItem from '@shared/ui/edit-item';
import { Typography } from 'antd';
import { useFormContext } from 'react-hook-form';
import { ModalCloseEvent, ModalOpenEvent } from '@processes/modal';
import FormComponent from '../form';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useLocale } from '@processes/app/lib';
import { EditCvModel } from '@features/edit-cv';
const { Text, Title } = Typography;

const translation = {
	title: {
		ru: 'Редактирование сертификата',
		en: 'Certificate editing',
	},
	list_certificates: {
		ru: 'Список добавленных сертификатов',
		en: 'List of added certificates',
	},
	empty_list: {
		ru: 'Нет добавленных сертификатов',
		en: 'No certificate added',
	},
};

const List = () => {
	const { setValue, watch } = useFormContext<EditCvModel.CvFormDto>();
	const [isDragging, setIsDragging] = useState<null | boolean>(null);
	const { locale } = useLocale();

	const certificationList = watch('certifications');

	const onDragHandler = (newState: EditCvModel.CvFormDto['certifications']) => {
		setValue('certifications', newState, { shouldDirty: typeof isDragging === 'boolean' });
	};
	const onDelete = (indexItem: string) => () => {
		const filtered = certificationList.filter(({ id }) => id !== indexItem);
		setValue('certifications', filtered, { shouldDirty: true });
	};
	const onEditCertification = (index: number) => (value: string) => {
		setValue(
			`certifications.${index}`,
			{ id: uuidv4(), item: { name: value } },
			{ shouldDirty: true },
		);
		ModalCloseEvent.Close();
	};
	const showModal = (id: string, name: string) => () => {
		const index = certificationList.findIndex((el) => el.id === id);
		ModalOpenEvent.Open({
			title: translation.title[locale],
			component: (
				<FormComponent
					modal
					closeModal={ModalCloseEvent.Close}
					onSetValue={onEditCertification(index)}
					defaultValue={name}
				/>
			),
		});
	};

	return (
		<div className={styles.list}>
			<div className={styles.title}>
				<Title>{translation.list_certificates[locale]}</Title>
			</div>
			<div className={styles.elements}>
				{certificationList.length === 0 && (
					<div className={styles.list_empty}>
						<Text>{translation.empty_list[locale]}</Text>
					</div>
				)}
				<ReactSortable
					onStart={() => setIsDragging(true)}
					onUnchoose={() => setIsDragging(false)}
					list={certificationList}
					setList={onDragHandler}
					animation={250}
					handle={'.handle'}
				>
					{certificationList.map(({ id, item }) => (
						<div className={styles.element} key={id}>
							<DragIndicatorSvg className={classNames(styles.dragIcon, 'handle')} />
							<div className={styles.certificate}>{item.name}</div>
							<div className={styles.more}>
								<EditItem onEdit={showModal(id, item.name)} onDelete={onDelete(id)} />
							</div>
						</div>
					))}
				</ReactSortable>
			</div>
		</div>
	);
};

export default List;
